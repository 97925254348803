import { Paper } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import blogs from "../../Components/data/Blogs/allblogs";
import PrevNext from "../../Components/PrevNext.js"
import { Helmet } from "react-helmet";

const SingleBlog = () => {
  const { link } = useParams();

  const uniquePost = blogs.filter(item => item.link === link)

  return (
    <div className="SingleBlog m-2">
      <Helmet>
        <title>{ uniquePost[0].mainTitle }</title>
        <meta
          name="description"
          content="The Glimpse Group is a Virtual Reality & Augmented Reality Platform Company Comprised of Multiple Software & Services Subsidiaries Creating VR/AR Solutions"
        />
      </Helmet>
      {blogs
        .filter(item => item.link === link)
        .map((item, index) => (
          <div className="single-blog-container" key={`${item.id}${index}`}>
            <div className="single-blog-top-banner"></div>
            <Paper elevation={6} className="single-blog-content">
              <h1 className='m-3'>{item.mainTitle}</h1>
              <div className="single-blog-date">
                <i className="far fa-calendar-alt"></i>
                <span> {item.date}</span>
              </div>
              <PrevNext nextPost={ item.next } prevPost={ item.prev } />
              <img
                src={item.image}
                alt={item.mainTitle}
                style={{ maxWidth: "100%", height: "auto" }}
              />
              <br />
              {item.blogContent}
            </Paper>
          </div>
        ))}
    </div>
  );
};

export default SingleBlog;
