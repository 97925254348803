import React from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import ourwork from '../Components/data/allourwork'

 
 const SingleVideo = () => {

  const { title } = useParams();

   return (
    <div className='SingleVideo'>
      <Helmet>
        <title>Our Work | { title } | The Glimpse Group</title>
        <meta
          name="description"
          content="The Glimpse Group is a Virtual Reality & Augmented Reality Platform Company Comprised of Multiple Software & Services Subsidiaries Creating VR/AR Solutions"
        />
      </Helmet>

     {ourwork.filter(item => item.title === title).map((item, index) => (
      <React.Fragment key={index} >
        <div className='w-full flex justify-center m-4 text-gray-600 font-light' style={{ fontFamily: 'Montserrat' }}>
          <h2>{item.title}</h2>
        </div>
        <div className='iframe-container'>
          <iframe src={`${item.video}&autoplay=1`} title="YouTube video player" frameBorder="{0}" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
        <div className='video-details'>
          {/* <h1>{item.title}</h1> */}
          {/* <i className="far fa-calendar-alt"></i>
          <span> { item.date }</span> */}
        </div>
      </React.Fragment>
    ))}
    </div>
   )
 }

 export default SingleVideo;


 <iframe width="560" height="315" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>