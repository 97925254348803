import React from "react";

function Nov2Year2021 () {
    return (
      <React.Fragment>
      <p>
      Following last year’s massive spike in online shopping, retailers are considering how to adjust their strategies for Black Friday 2021. Our preference for ecommerce is not a new trend: online shopping has been overtaking in-person shopping for years, forcing retailers to respond to this shift in retail habits. The trend isn’t slowing down either, as over half of shoppers plan to conduct their shopping online for Black Friday 2021.
      </p>
      <p>
      Nonetheless, ecommerce still faces inherent issues which put it at a disadvantage to in-person shopping. The biggest issue for online clothing shopping is the inability to try on, test out, or otherwise “experience” a product before buying it. This leads to a significantly higher rate of returns compared to in-person shopping, costing both customers and companies time and money. By utilizing AR technology, retail companies can reintroduce the “experience” stage of shopping to ecommerce. Virtual fitting room technology enables customers to try on products from home using AR filters, reducing returns and leaning into the broader transition toward shopping online.
      </p>
      <h2>From Brick-and-Mortar to Click-and-Order</h2>
      <p>
      ​​Even prior to the pandemic, Black Friday shoppers—and shoppers in general—preferred online shopping to in-person shopping. The transition from brick-and-mortar to click-and-order is a well-documented trend informing retail strategy for several years. In 2019, more people shopped online than in-person on Black Friday; for the first time, online purchases actually peaked on Black Friday instead of the designated online shopping day, Cyber Monday.
      </p>
      <p>
      While the shift away from physical storefronts began long before 2020, the pandemic rapidly accelerated the transition. Researchers accurately predicted that Black Friday 2020 would see a sharp decrease in brick-and-mortar shopping in favor of online shopping: retail clothing sales saw a 43.5% decline during the pandemic, the most dramatic decline in any retail sector. Even with the return to in-person shopping as Covid cases wane in many parts of the U.S., the majority of shoppers still prefer to shop online. More people will shop online than in-person for Black Friday 2021, and it’s up to retailers to develop their ecommerce experience accordingly.
      </p>
      <h2>Fashion Ecommerce’s Biggest Hurdle</h2>
      <p>
      The advantages online shopping has over in-person shopping—convenience, product availability, and, during the pandemic, safety—have allowed it to overtake in-person shopping. However, there are elements of in-person shopping crucial to moving products that ecommerce has failed to replicate digitally: strategies like doorbusters, visualized scarcity, and impulse purchases haven’t translated well to digital retail spaces yet. Fashion ecommerce particularly suffers from one disadvantage compared to in-person shopping: the loss of the try-on experience.
      </p>
      <p>
      Lacking the “experience” stage of shopping is an issue for ecommerce generally, but it’s especially acute with online fashion retail. Although the majority of consumers prefer to buy their clothing online, fashion ecommerce still suffers from losing the fitting room: nearly half of shoppers said their biggest concern with online shopping was the inability to try on clothes before buying them. Of all the reasons people returned online clothing purchases, 73% said it was because they weren’t able to try the clothes on first. The return and replacement process adds significant costs to retailers, as reverse logistics like shipping costs, labor time spent processing returns, and costs associated with customer support present mounting expenses with the ecommerce market continuing to grow. With some seeing return rates as high as 30%, ecommerce retailers need to address the inability to try on products when shopping online.
      </p>
      <h2>AR and The Virtual Fitting Room</h2>
      <p>
      Augmented reality offers a solution to the problem. With virtual fitting rooms, shoppers can virtually try on products such as hats, goggles, and shoes from the comfort of their own home using AR filters. Although AR fitting room technology is on the cusp of mass deployment, it’s currently in a liminal space where consumers know about it and want it, but don’t have much experience using it. While only 7% of shoppers have actually used a virtual fitting room, 76% are interested in experiencing fitting room technology, especially if it offers a greater selection of products and reduces returns. Retail consumers have been excited by the concept of AR for years, and retailers are now aiming to incorporate AR technology into their ecommerce experiences.
      </p>
      <p>
      AR companies such as QReal, a subsidiary of The Glimpse Group, are leading the charge introducing AR technology to ecommerce platforms. QReal’s development of AR technology for brands such as Bollé illustrates a solution to the ecommerce try-on problem, as customers are able to simulate from home what a product would look like on them using AR filters. Virtual fitting rooms allow users to cycle through product models and color variations instantaneously, enabling them to decide which version of a product best suits them. Being able to “test out” products and inspect them from all angles can greatly reduce the high rate of returns which ecommerce suffers from. Trying on products virtually before purchasing brings online shopping closer than ever to replicating the in-person shopping experience. 
      </p>
      <h2>Conclusion</h2>
      <p>
      Retail has been shifting away from in-person shopping toward online shopping for years, a trend which pandemic only accelerated. However, the inability to try on clothes and the subsequent frequency of returns are inherent issues which online fashion retailers must reckon with. Virtual try-on technology, and AR technology as a whole, offer solutions to the problem by allowing consumers to try on a variety of makes and models before deciding to purchase.
      </p>
      <p>
      The implementation of AR tech in retail isn’t limited to ecommerce either: companies such as Lowes and Walgreens have released apps that guide customers through stores using augmented reality, navigating shoppers according to their shopping list. AR-powered mirrors achieve a similar effect as the virtual fitting room but in brick-and-mortar locations, as brands such as Topshop feature smart mirrors in their stores that allow shoppers to circumvent the fitting room process entirely. Whether you’re shopping virtually from home or in-person at the store, AR technology will soon be a central feature in the retail shopping experience.
      </p>
      <div className="border-top">
      <span className="disclaimer">
        Any content provided by our bloggers or authors are of their opinion. The views and opinions expressed are those of the authors and do not necessarily reflect the official policy or position of The Glimpse Group. In General The information provided by The Glimpse Group on https://www.theglimpsegroup.com/ (the “Site”) is for general information purposes only. All information on the Site is provided in good faith, however we make no representation or warranty, of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
      </span>
      </div>
      </React.Fragment>

    )
  }
  
  export default Nov2Year2021;