import { React } from "react"
import allindustries from '../Components/data/allindustries'
import { AiOutlineCaretRight } from '@react-icons/all-files/ai/AiOutlineCaretRight';


import { Link } from "react-router-dom";


function Solutions () {

  return ( 
    <div className="solution-boxes">
      <div className="card-grid-responsive">
          {allindustries.map((item, index) => {
            return (
              <Link to={`/industry-solutions/${item.link}`} key={`${index}${item.id}`}>
                <div className="motion-card"  >
                  <div className="title-content">
                    <h3>
                      {item.btnTitle}
                      <span style={{fontSize: "13px"}}> <AiOutlineCaretRight /></span>
                    </h3>
                    <img src={item.hIcon} alt={item.name} />
                  </div>
                </div>
              </Link>
            )
          })} 
      </div>
    </div>
      
    
   
  )

}

export default Solutions;