import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Clients from "../Components/Clients";
import Companies from "../Components/Companies";
import companies from "../Components/data/allcompanies";
import Solutions from "../Components/Solutions";
import VideoJS from "../Components/VideoJS";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function HomePage() { 
  const [companieItems] = useState(companies);
  const b830 = useMediaQuery('(max-width:830px)')// theme.breakpoints.down("md"));

  return (
    <div className="HomePage">
      <Helmet>
        <title>The Glimpse Group | VR and AR Enterprise Solutions</title>
        <meta
          name="description"
          content="The Glimpse Group is a Virtual Reality ( VR ) & Augmented Reality ( AR ) Platform Company comprised of multiple subsidiary companies offering VR and AR Solutions for enterprise"
        />
      </Helmet>
      <section className="home-content">
        {/* <div className="home-text">
          <Typography variant="h1" align="center">
            Unlocking the potential of VR & AR
          </Typography>
          <h3>
            The Glimpse Group (Nasdaq: VRAR) is an immersive technology company
            with an ecosystem of subsidiaries offering AR and VR solutions
            across sectors
          </h3>
        </div> */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          zIndex={99}
          height="91.45vh"
          width="100%"
          color="white"
        >
          <div align="center" className='p-4 text-2xl md:text-4xl lg:text-5xl md:w-11/12 ' style={{ fontFamily: 'Montserrat'}}>
            <h1>
              Unlocking the Benefits of <br /> Virtual Reality & Augmented Reality
            </h1>
            
          </div>      
          <div align="center" className='w-full'>
            <h2 className='p-2 font-light text-xl lg:text-2xl w-10/12' style={{ fontFamily: 'Montserrat'}}>
            The Glimpse Group <a href='https://ir.theglimpsegroup.com/' target='_blank' rel="noreferrer" className='transform duration-700 hover:ease hover:text-blue-400'>
            (Nasdaq: VRAR)</a> is an immersive technology 
            { !b830 ? <br /> : " " }
            platform company, comprised of a diverse ecosystem of companies 
            { !b830 ? <br /> : " " } 
            providing enterprise-focused AR and VR software 
            { !b830 ? <br /> : " " } 
            and services across sectors
            </h2>
          </div>
        </Box>
        <VideoJS />
      </section>

      <section className="max__width m__top">
        <div className="container-text__width text__center">
          <h2 className="pb-clr text-gray-600 font-light" style={{ fontFamily: 'Montserrat' }}>Our Clients & Partners</h2>
        </div>
        <Clients />
      </section>

      <section className="bg-light p__y">
        <div className="max__width m__y">
          <div className="container-text__width text__center flex-column content-center">
            <h2 className='font-light text-gray-600 m-4' style={{ fontFamily: 'Montserrat' }}>Our Business Software & Solutions</h2>
            <div className='flex justify-center'>
              <p className="m__bottom text-lg w-10/12 md:w-2/3 font-light text-gray-600" style={{ fontFamily: 'Montserrat' }}>
                Within The Glimpse Group, we have extensive experience across a
                wide range of sectors, from Education to Corporate Training,
                Marketing to Healthcare.
              </p>
            </div>
            
          </div>
          <Solutions />
        </div>
      </section>

      <section className="max__width m__top" id="companies">
        <div className="container-text__width text__center">
          <h2 className='pb-2 font-light text-gray-600' style={{ fontFamily: 'Montserrat' }}>Our Companies</h2>
          <div className='flex justify-center'>
            <p className="m__bottom w-10/12 md:w-2/3 text-lg font-light text-gray-600" style={{ fontFamily: 'Montserrat' }}>
              As part of the Glimpse ecosystem, our companies focus on their
              core competencies while benefiting from one another's industry
              connections, IP, resources and expertise. Together, we develop
              innovative VR/AR solutions for a variety of sectors, creating the
              products and software services that will form the metaverse.
            </p>
          </div>
          
        </div>
        <Companies companieItem={companieItems} />
      </section>
    </div>
  );
}

export default HomePage;
