import React from "react";
import { ExternalLink } from "react-external-link";

import March22Item1 from "../../assets/images/news/insider/march22item1.png";
import March22Item2 from "../../assets/images/news/insider/march22item2.png";
import March22Item3 from "../../assets/images/news/insider/march22item3.png";

import Sector5 from "../../assets/images/news/blog-sector5.PNG";
import Forbes from "../../assets/images/news/forbes.jpg";
// import NFT from '../../assets/images/news/nft.png'
import DJSmith from "../../assets/images/news/dj-smith.PNG";
// import IHG from '../../assets/images/news/IHG.png'
// import NFTs4Charity from "../../assets/images/news/nfts-charity.png";
import Financial from "../../assets/images/news/financial.PNG";
// import Meebits from '../../assets/images/news/meebits.png'
import DID from "../../assets/images/news/d-id.png";
import LDMicro from "../../assets/images/news/ld-micro.png";
import Exchange from "../../assets/images/news/exchange.png";
import NorthStar from "../../assets/images/news/north-star.png";
// import Chimera from '../../assets/images/news/chimera.jpg'
// import Metaverse from '../../assets/images/news/metaverse.PNG'
// import Xrterra from '../../assets/images/news/xrterra.png'
import { Helmet } from "react-helmet";

function Insider() {
  return (
    <div className="Insider">
      <Helmet>
        <title>Glimpse Insider - VR/AR for Business</title>
      </Helmet>
      <section className="main-content">
        <div className="card-content">
          <h1>Glimpse Insider</h1>
          <div className='flex justify-center'>
            <p className='w-2/3 text-xl'>
              The Glimpse Insider briefs readers on the most compelling
              developments in Virtual Reality and Augmented Reality, curated by
              our industry experts at The Glimpse Group.
            </p>
          </div>
          

          {/* <span>Not a Glimpse Insider subscriber? Click below to have our newsletter sent straight to your inbox!</span>

          <div>
            <button className='btn'>Subscribe</button>
          </div> */}
        </div>
      </section>
      <section className="newsletters">
        <div className="title-date">
          <ExternalLink href="https://us18.campaign-archive.com/?u=f2f3816039eb8aeb8398a55dd&id=6857e459b7">
            <h3 className="btn">March 22, 2022 Newsletter</h3>
          </ExternalLink>
          <br />
        </div>
        <div className="card-grid-responsive">
          <div className="card">
            <div className="top-img">
              <img src={March22Item1} alt="Forbes" />
            </div>
            <div className="card-content">
              <h4>
                The Glimpse Group was issued its 6th VR/AR Reality U.S. patent
              </h4>
            </div>
            <ExternalLink href="https://www.accesswire.com/viewarticle.aspx?id=693046&token=chync1ngcuvu7zi6rp32">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>
          <div className="card">
            <div className="top-img">
              <img src={March22Item2} alt="NFT" />
            </div>
            <div className="card-content">
              <h4>Watch this interview set in the metaverse</h4>
            </div>
            <ExternalLink href="https://www.youtube.com/watch?v=mbGrwbnbblg">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={March22Item3} alt="DJ Smith" />
            </div>
            <div className="card-content">
              <h4>
                BlackRock predicts that 2022 will be an important year for the
                metaverse
              </h4>
              <p>
                The Glimpse Group Chief Creative Officer and Co-Founder DJ Smith
                sat down with The VR/AR Association podcast to discuss his rise
                through the VR/AR space.
              </p>
            </div>
            <ExternalLink href="https://markets.businessinsider.com/news/stocks/metaverse-outlook-blackrock-investing-big-tech-meta-apple-microsoft-vr-2022-2?utmSource=twitter&utmContent=referral&utmTerm=topbar&referrer=twitter">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>
        </div>

        <div className="title-date">
          <ExternalLink href="https://us18.campaign-archive.com/?u=f2f3816039eb8aeb8398a55dd&id=4f71683351">
            <h3 className="btn">January 14, 2022 Newsletter</h3>
          </ExternalLink>
          <br />
        </div>
        <div className="card-grid-responsive">
          <div className="card">
            <div className="top-img">
              <img src={Forbes} alt="Forbes" />
            </div>
            <div className="card-content">
              <h4>The Metaverse</h4>
              <p>
                Gil Perry, CEO of D-ID, and Lyron Bentovim, CEO of The Glimpse
                Group, talk with Forbes Senior Contributor Jack Kelly to discuss
                laying the groundwork for the transition into the Metaverse.
              </p>
            </div>
            <ExternalLink href="https://www.forbes.com/sites/jackkelly/2021/12/13/remote-work-job-interviews-business-meetings-and-live-events-will-all-be-conducted-on-the-meterverse-in-the-near-future/?sh=4d67cde67023">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>
          <div className="card">
            <div className="top-img">{/* <img src={NFT} alt="NFT" /> */}</div>
            <div className="card-content">
              <h4>Limited-Run NFT</h4>
              <p>
                The Glimpse Group celebrated 2021 by minting our very own
                limited-run NFT; each medal serves as an access key to future
                content, including Glimpse's VR showcase piece.
              </p>
            </div>
            <ExternalLink href="https://www.linkedin.com/posts/jordangutt_nfts-web3-metaverse-activity-6886688344277819392-QqwM/">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={DJSmith} alt="DJ Smith" />
            </div>
            <div className="card-content">
              <h4>The VR/AR Association Podcast</h4>
              <p>
                The Glimpse Group Chief Creative Officer and Co-Founder DJ Smith
                sat down with The VR/AR Association podcast to discuss his rise
                through the VR/AR space.
              </p>
            </div>
            <ExternalLink href="https://www.youtube.com/watch?v=w07iyLBaEa0">
              <button className="btn">Listen Now</button>
            </ExternalLink>
          </div>
        </div>

        <div className="title-date">
          <ExternalLink href="https://us18.campaign-archive.com/?u=f2f3816039eb8aeb8398a55dd&id=519e11d850">
            <h3 className="btn">December 10, 2021 Newsletter</h3>
          </ExternalLink>
          <br />
        </div>
        <div className="card-grid-responsive">
          <div className="card">
            <div className="top-img">
              <img src={Sector5} alt="Sector 5" />
            </div>
            <div className="card-content">
              <h4>Sector 5 Digital</h4>
              <p>
                The Glimpse Group announced that it has entered into a
                definitive agreement to purchase Sector 5 Digital, a leading
                immersive technology company.
              </p>
            </div>
            <ExternalLink href="https://www.accesswire.com/viewarticle.aspx?id=675680&token=k53c35hr39ua862pnwci">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>
          <div className="card">
            <div className="top-img">
              {/* <img src={IHG} alt="Immersive Health Group" /> */}
            </div>
            <div className="card-content">
              <h4>Immersive Health Group</h4>
              <p>
                The Glimpse Group subsidiary company Immersive Health Group
                announces a 5-year software and services contract with Boston
                Medical Center to develop and implement VR experiences
                responding to maternal health emergencies in rural and
                underserved settings.
              </p>
            </div>
            <ExternalLink href="https://www.accesswire.com/viewarticle.aspx?id=675191&token=agga9ebt3burevcxmn0t">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>

          <div className="card">
            <div className="top-img">
              {/* <img src={NFTs4Charity} alt="NFTs4Charity" /> */}
            </div>
            <div className="card-content">
              <h4>NFTs4Charity</h4>
              <p>
                The Glimpse Group is collaborating on an online gallery
                experience for an upcoming NFT charity auction of Paul Michael
                Glaser's artwork benefitting the Elizabeth Glaser Pediatric AIDS
                Foundation.
              </p>
            </div>
            <ExternalLink href="https://www.einnews.com/pr_news/557381768/nfts4charity-partners-with-artist-paul-michael-glaser-on-nft-minting-auction">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>
        </div>

        <div className="title-date">
          <ExternalLink href="https://us18.campaign-archive.com/?u=f2f3816039eb8aeb8398a55dd&id=26ee83d2dc">
            <h3 className="btn">November 19, 2021 Newsletter</h3>
          </ExternalLink>
          <br />
        </div>
        <div className="card-grid-responsive">
          <div className="card">
            <div className="top-img">
              <img src={Financial} alt="Financial" />
            </div>
            <div className="card-content">
              <h4>The Glimpse Group Announces</h4>
              <p>
                The Glimpse Group entered into a Master Services Agreement and
                Statement of Work with a leading social media and metaverse
                company for a $1.0 million software and services contract.
              </p>
            </div>
            <ExternalLink href="https://www.accesswire.com/viewarticle.aspx?id=673282&token=6k2cuv6a0b21c8320cz6">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>
          <div className="card">
            <div className="top-img">
              {/* <img src={Meebits} alt="Meebits" /> */}
            </div>
            <div className="card-content">
              <h4>The Avenue of Meebits</h4>
              <p>
                The Glimpse Group subsidiary company Post Reality is pushing new
                boundaries for NFT functionality by rendering Meebits in
                Augmented Reality for the first time.
              </p>
            </div>
            <ExternalLink href="https://twitter.com/PostRealityAR/status/1460608690764103685">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={DID} alt="D-ID" />
            </div>
            <div className="card-content">
              <h4>The Glimpse Group and D-ID Partnership</h4>
              <p>
                The Glimpse Group and D-ID announced a partnership to bring life
                to the metaverse with AI, VR & AR.
              </p>
            </div>
            <ExternalLink href="https://vimeo.com/641137528?embedded=true&source=video_title&owner=136600924">
              <button className="btn">Watch Now</button>
            </ExternalLink>
          </div>
        </div>

        <div className="title-date">
          <ExternalLink href="https://us18.campaign-archive.com/?u=f2f3816039eb8aeb8398a55dd&id=7008a4b03d">
            <h3 className="btn">November 3, 2021 Newsletter</h3>
          </ExternalLink>
          <br />
        </div>

        <div className="card-grid-responsive">
          <div className="card">
            <div className="top-img">
              <img src={LDMicro} alt="LD Micro" />
            </div>
            <div className="card-content">
              <h4>A Glimpse into the Business Metaverse</h4>
              <p>
                Lyron Bentovim discussed the transformative potential of the
                metaverse in an LD Micro keynote titled “A Glimpse into the
                Business Metaverse.”
              </p>
            </div>
            <ExternalLink href="https://vimeo.com/manage/videos/637590871">
              <button className="btn">Watch Now</button>
            </ExternalLink>
          </div>
          <div className="card">
            <div className="top-img">
              <img src={Exchange} alt="Frankfurt Stock Exchange" />
            </div>
            <div className="card-content">
              <h4>Publicly Listing On The Frankfurt Stock Exchange</h4>
              <p>
                The Glimpse Group announced that it has been listed on the
                Frankfurt Stock Exchange under the trading symbol "9DR".
              </p>
            </div>
            <ExternalLink href="https://www.accesswire.com/viewarticle.aspx?id=669999&token=747kx08kyyi8i0pprorm">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={NorthStar} alt="North Star" />
            </div>
            <div className="card-content">
              <h4>Virtual Reality Therapy to Hospitalized Children</h4>
              <p>
                Foretell Reality and GameChanger Charity partnered to deliver
                Virtual Reality therapy to hospitalized children.
              </p>
            </div>
            <ExternalLink href="https://www.forbes.com/sites/jackkelly/2021/12/13/remote-work-job-interviews-business-meetings-and-live-events-will-all-be-conducted-on-the-meterverse-in-the-near-future/?sh=4d67cde67023">
              <button className="btn">Learn More</button>
            </ExternalLink>
          </div>
        </div>

        {/* <div className='title-date'>
            <ExternalLink  href='https://us18.campaign-archive.com/?u=f2f3816039eb8aeb8398a55dd&id=ac1addd2e4'>
              <h3 className="btn">Glimpse Acquires XR Terra as 11th Subsidiary</h3>
            </ExternalLink>
            <br/>
            <i className="far fa-calendar-alt"></i>
            <span> 10/12/2021</span>
          </div>
          <div className='card-grid-responsive'>
            <div className='card'>
              <div className='top-img'>
                <img src={ Xrterra } alt="XR Terra" />
              </div>
              <div className='card-content'>
                <h4>XR Terra</h4>
                <p>The Glimpse Group (Nasdaq: VRAR) announces acquisition of new subsidiary company: XR Terra, a provider of VR & AR software development and design training courses.</p>
              </div>
              <ExternalLink href="https://www.linkedin.com/posts/the-glimpse-group_xr-terra-vr-ar-software-development-and-activity-6849321581081055232-eSkf/">
                  <button className='btn'>Learn More</button>
                </ExternalLink>
            </div>
            <div className='card'>
              <div className='top-img'>
                <img src={ Metaverse } alt="Metaverse" />
              </div>
              <div className='card-content'>
                <h4>What is the ‘metaverse’?</h4>
                <p>Michael Cadoux, General Manager of Glimpse subsidiary company QReal, discussed the metaverse and standardization of AR assets with The Washington Post.</p>
              </div>
              <ExternalLink href="https://www.washingtonpost.com/technology/2021/08/30/what-is-the-metaverse/">
                  <button className='btn'>Learn More</button>
                </ExternalLink>
            </div>

            <div className='card'>
              <div className='top-img'>
                <img src={ Chimera } alt="Chimera" />
              </div>
              <div className='card-content'>
                <h4>Pagoni VR's Chimera platform</h4>
                <p>The Glimpse Group and Temple University announced the extension of virtual reality classroom engagement, utilizing Pagoni VR's Chimera platform.</p>
              </div>
              <ExternalLink href="https://www.linkedin.com/feed/update/urn:li:activity:6835553276100919296/">
                  <button className='btn'>Learn More</button>
                </ExternalLink>
            </div>
          </div> */}
      </section>
    </div>
  );
}

export default Insider;
