import React from "react"
// import { useState } from "react";
import Slider from "react-slick";
import { ExternalLink } from "react-external-link"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import allclients from "./data/allclients";

export default function Clients() {

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };


  const settings = {
    className: 'client-slide',
    slidesToScroll: 5,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    speed: 700,
    easgin: 'linear',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
    ]
  };


  return (
    <React.Fragment>
      <Slider {...settings}>
        {allclients.map((item, idx) => (
          <div key={item.id}>
            <div className="slide">
                <ExternalLink href={item.link}>
                  <img src={item.image} alt={item.alt} />
                </ExternalLink>
            </div>        
            <div className="slide">
                <ExternalLink href={item.link2}>
                  <img src={item.image2} alt={item.alt2} />
                </ExternalLink>
            </div>   
          </div>
        ))}
      </Slider>
    </React.Fragment>
  );
}
