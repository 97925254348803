import "./App.scss";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import theme from "./Components/Theme";
import { ThemeProvider } from "@material-ui/core/styles";
import React, {useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Page404 from "./Pages/Page404";
import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/AboutPage";
import IndustryS from "./Pages/IndustryS";
import OurWork from "./Pages/OurWork";
import ScrollToTop from "./Components/ScrollToTop";
import Insider from "./Pages/News/Insider";
import Blogs from "./Pages/News/Blogs";
import PressCoverage from "./Pages/News/PressCoverage";

import SingleRelease from "./Pages/News/SingleRelease";
import PressReleases from "./Pages/News/PressReleases";

import Contact from "./Components/Contact";
import Privacy from "./Components/Privacy";
import FAQ from "./Components/FAQ";
import ConsentPopUp from "./Components/ConsentPopUp";
import SingleBlog from "./Pages/News/SingleBlog";
import SingleVideo from "./Pages/SingleVideo";
import OurSubsidiaries from "./Pages/OurSubsidiaries";
import NewsSignUp from './Components/NewsSignUp'
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-99077717-1"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

function App() {
  window.dataLayer.push({
    event: 'pageview'
  });
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Helmet>
        <title>The Glimpse Group</title>
        <meta
          name="description"
          content="The Glimpse Group, a Virtual and Augmented Reality platform company, comprised of multiple VR and AR software and services companies."
        />
        <meta
          name="keywords"
          content="virtual, augmented, reality, VR, AR, software"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Header />
      </ThemeProvider>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/about-glimpse" element={<AboutPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faq" element={<FAQ />} />

        {/* industry-solutions */}
        <Route path="/industry-solutions/:link" element={<IndustryS />} />
          

        {/* glimpse-blog */}
        <Route path="/news/glimpse-blog/:link" element={<SingleBlog />} />
          
        <Route path="/news/glimpse-blog" element={<Blogs />} />

        <Route path="/become-a-vr-ar-engineer"
          element={<Navigate to="/news/glimpse-blog/become-a-vr-ar-engineer" replace />}
        />

        {/* Press Releases */}
        <Route path="/news/press-releases/:slug" element={<SingleRelease />} />
          
        <Route path="/news/press-releases" element={<PressReleases />} />

        {/* Our Work */}
        <Route path="/our-work/:title" element={<SingleVideo />} />
          
        <Route path="/our-work" element={<OurWork />} />
        <Route path="/our-companies" element={<OurSubsidiaries />} />

        <Route path="/news/press-coverage" element={<PressCoverage />} />
        <Route path="/news/glimpse-insider" element={<Insider />} />
        <Route element={<Page404 />} />
        
      </Routes>
      <Contact />
      {/* <NewsSignUp /> */}
      <Footer />
      <ConsentPopUp />
    </Router>
  );
}

export default App;
