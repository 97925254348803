import React, { useEffect, useState } from 'react'
import { Grid, Paper } from "@material-ui/core";
import axios from 'axios';

function NewsSignUp() {
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [org, setOrg] = useState();

    function handleEmailChange(event){
        setEmail(event.target.value)
    }

    function handleFirstChange(event){
        setFirstName(event.target.value)
    }

    function handleLastChange(event){
        setLastName(event.target.value)
    }

    function handleOrgChange(event){
        setOrg(event.target.value)
    }

    {/*
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://use.typekit.net/foobar.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);
    */}

    function submitForm(event){
        axios({
            method: "post",
            url: 'https://api.chloe.insightly.com/v3.1/Forms/MTEyNTU4MC04NzIzLTIwMDkxMDU%3d',
            // url: "https://chloe.insightly.services/Forms/MTEyNTU4MC04NzIzLTIwMDkxMDU%3d",
            data: {
                FIRST_NAME: firstName,
                LAST_NAME: lastName,
                EMAIL_ADDRESS: email,
                insightly_Organization: org
            },
            headers: { 
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                'Access-Control-Allow-Credentials':true
            },
        })
        .then(function (response) {
            //handle success
            console.log('success', response);
        })
        .catch(function (response) {
            //handle error
            console.log('fail', response);
        });
        event.preventDefault();
    }

    return (
        <div className="w-full p-4 flex flex-col filter items-center drop-shadow-md" style={{ backgroundColor: '#767C81' }}>
            <p className='text-center text-lg font-light text-light text-xl mb-3' style={{ fontFamily: "Montserrat" }}>Keep up with Glimpse! <span className='ml-4'></span> Join our mailing list</p>
            <form name='insightly_form' className='flex flex-col items-center' data-form-id='8723' onSubmit={ submitForm }>
                <div className='grid grid-cols-1 md:grid-cols-2'>
                    <div className='m-2 flex justify-end items-center'>
                        <label htmlFor='insightly_First' className='text-light mr-2 mb-0 font-light' style={{ fontFamily: "Montserrat" }}>First </label><input id='insightly_First' className='p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-opacity-10' name='First' autoComplete='off' type='text' onChange={handleFirstChange} style={{ width: '250px' }} />
                    </div>
                    <div className='m-2 flex justify-end items-center'>
                        <label htmlFor='insightly_Last' className='text-light mr-2 mb-0 font-light' style={{ fontFamily: "Montserrat" }}>Last </label><input id='insightly_Last' className='p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-opacity-10' name='Last' autoComplete='off' type='text' onChange={handleLastChange} style={{ width: '250px' }} />
                    </div>
                    <div className='m-2 flex justify-end items-center'>
                        <label htmlFor='insightly_Email' className='text-light mr-2 mb-0 font-light' style={{ fontFamily: "Montserrat" }}>Email* </label> <input id='insightly_Email' className='p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-opacity-10' name='email' autoComplete='off' type='text' onChange={handleEmailChange} style={{ width: '250px' }} required />
                    </div>
                    <div className='m-2 flex justify-end items-center'>
                        <label htmlFor='insightly_Organization' className='text-light mr-2 mb-0 font-light' style={{ fontFamily: "Montserrat" }}>Organization </label><input id='insightly_Organization' className='p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-opacity-10' autoComplete='off' name='Organization' type='text' onChange={handleOrgChange} style={{ width: '250px' }} />
                    </div>
                </div> 
                <p className='text-light mt-2 font-light' style={{ fontFamily: "Montserrat" }}>*Required Fields</p>
                {/* recaptcha block 
                <button className='g-recaptcha btn w-1/2 p-2 m-3' data-sitekey={process.env.REACT_APP_INSIGHTLY_RECAPTCHA} data-callback='onSubmit' data-action='submit' style={{ fontFamily: "Montserrat" }}>Sign Up</button>
                */}
                <button className='btn w-1/2 p-2 m-3' data-callback='onSubmit' data-action='submit' style={{ fontFamily: "Montserrat" }}>Sign Up</button>
            </form>
        </div>
    )
}

export default NewsSignUp
