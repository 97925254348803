import React from "react";
import { useParams } from "react-router-dom";
import { ExternalLink } from "react-external-link";
import allindustries from "../Components/data/allindustries";
import Solutions from "../Components/Solutions";
import { Helmet } from "react-helmet";

function IndustryS() {
  const { link } = useParams();

  return (
    <div className="Solutions">
      { link === 'architecture-engineering-construction' && 
        <Helmet>
          <title>Reduce rework costs | AR for Architecture, Engineering and Construction | The Glimpse Group</title>
          <meta
            name="description"
            content="Reduce rework costs and increase stakeholder buy-in with realistic 3D renderings. Enhance Building Information Modeling with AR. "
          />
        </Helmet>
      }
      { link === 'k12-education' && 
        <Helmet>
          <title>Immersive education tools | Enhance classroom & remote learning | The Glimpse Group</title>
          <meta
            name="description"
            content="Immersive education tools deepen classroom learning and elevate remote learning. Introduce students to emerging VR early."
          />
        </Helmet>
      }
      { link === 'higher-education' && 
        <Helmet>
          <title>Challenge and inspire students | Immersive education tools | The Glimpse Group </title>
          <meta
            name="description"
            content="Enhance higher education with innovative learning solutions and immersive VR environments for lectures and classes."
          />
        </Helmet>
      }
      { link === 'food-hospitality' && 
        <Helmet>
          <title>Memorable customer experiences | Lifelike VR tours and travel destinations | The Glimpse Group</title>
          <meta
            name="description"
            content="Spotlight travel destinations with VR tourism experiences. Curate a showcase of your accommodations and amenities."
          />
        </Helmet>
      }
      { link === 'professional-training' && 
        <Helmet>
          <title>Hard skills and soft skills | Accelerate training and onboarding with VR | The Glimpse Group</title>
          <meta
            name="description"
            content="Upskill your workforce with realistic VR simulations. Practice and acquire technical knowledge and interpersonal skills faster."
          />
        </Helmet>
      }
      { link === 'finance-data-visualization' && 
        <Helmet>
          <title>Visualize data in 3D | Bring data to life using VR | The Glimpse Group</title>
          <meta
            name="description"
            content="Unlock new insights by visualizing and analyzing data using VR. Revolutionize your workflow by collaborating and presenting in VR. "
          />
        </Helmet>
      }
      { link === 'healthcare' && 
        <Helmet>
          <title>Healthcare VR solutions | Improve patient experience | The Glimpse Group</title>
          <meta
            name="description"
            content="Train the next generation of healthcare professionals in VR. Improve the patient experience with engaging VR and AR solutions."
          />
        </Helmet>
      }
      { link === 'marketing' && 
        <Helmet>
          <title>Enhance ecommerce | Immersive retail experiences | The Glimpse Group </title>
          <meta
            name="description"
            content="Enhance ecommerce sites and create brand engagement opportunities with VR and AR retail solutions."
          />
        </Helmet>
      }
      { link === 'events-and-meetings' && 
        <Helmet>
          <title>Immersive coworking environments | Memorable virtual events | The Glimpse Group</title>
          <meta
            name="description"
            content="Increase productivity with seamless coworking in VR. Create memorable and accessible virtual events for attendees everywhere."
          />
        </Helmet>
      }
      <section className="intro-sec">
        <div className="main__container">
          <h1 className="text-center m__bottom text-gray-600 font-light" style={{ fontFamily: 'Montserrat' }} >Solutions by Sector</h1>
        </div>
      </section>
      
      {allindustries
        .filter(item => item.link === link)
        .map((item, index) => (
          <section key={`${item.id}${index}`} className="outer-container">
            <div className="inner__container">
              <div className="grid-container">
                <div className="box">
                  <iframe
                    src={item.video}
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
                <div className="box">
                  <h2>{item.name}</h2>
                  <p>{item.mainText}</p>
                </div>
              </div>
            </div>
            <div className="inner__container">
              <div className="features">
                <div className="grid-features-container">
                  <div className="box text-right">
                    <div className="bullet-right">
                      <h3 className='font-bold text-md'>{item.bTitle1}</h3>
                      <p>{item.bText1}</p>
                    </div>
                    <div className="bullet-right">
                      <h3 className='font-bold text-md'>{item.bTitle2}</h3>
                      <p>{item.bText2}</p>
                    </div>
                  </div>
                  <div className="box">
                    <div className="img-box">
                      <img src={item.fImage} alt={item.name} />
                    </div>
                  </div>
                  <div className="box">
                    <div className="bullet">
                      <h3 className='font-bold text-md'>{item.bTitle3}</h3>
                      <p>{item.bText3}</p>
                    </div>
                    <div className="bullet">
                      <h3 className='font-bold text-md'>{item.bTitle4}</h3>
                      <p>{item.bText4}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Mobile */}
              <div className="inner__container">
                <div className="features-mb">
                  <div className="grid-features-container-mb">
                    <div className="box-1">
                      <div className="bullet">
                        <h3 className='font-bold text-md'>{item.bTitle1}</h3>
                        <p className="margin-b">{item.bText1}</p>
                      </div>
                      <div className="bullet">
                        <h3 className='font-bold text-md'>{item.bTitle2}</h3>
                        <p>{item.bText2}</p>
                      </div>
                      <div className="box">
                        <div className="img-box">
                          <img src={item.fImage} alt={item.name} />
                        </div>
                      </div>
                    </div>
                    <div className="box-2">
                      <div className="bullet">
                        <h3 className='font-bold text-md'>{item.bTitle3}</h3>
                        <p className="margin-b">{item.bText3}</p>
                      </div>
                      <div className="bullet">
                        <h3 className='font-bold text-md'>{item.bTitle4}</h3>
                        <p className="margin-b">{item.bText4}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {item.id !== 112 && (
              <div className="inner__container">
                <div className="container">
                  <div className="title-box">
                    <h2 className="text-gray-600 font-light" style={{ fontFamily: 'Montserrat' }}>
                      Our Clients & Partners
                    </h2>
                  </div>
                  <div className="partners">
                    <div className="grid-partners-container">
                      <div className="box">
                        <ExternalLink href={item.pLinks1}>
                          <img src={item.pImages1} alt={item.pAlt1} /> 
                        </ExternalLink>
                      </div>
                      <div className="box">
                        <ExternalLink href={item.pLinks2}>
                          <img src={item.pImages2} alt={item.pAlt2} />
                        </ExternalLink>
                      </div>
                      <div className="box">
                        <ExternalLink href={item.pLinks3}>
                          <img src={item.pImages3} alt={item.pAlt3} />
                        </ExternalLink>
                      </div>
                      <div className="box">
                        <ExternalLink href={item.pLinks4}>
                          <img src={item.pImages4} alt={item.pAlt4} />
                        </ExternalLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        ))}
        <div className='m-6'>
          <Solutions  />
        </div>
    </div>
  );
}

export default IndustryS;
