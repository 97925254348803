import React from 'react';
import Logo from '../assets/images/glimpsewhite-alt.png'
import { Link } from "react-router-dom";
// import { ExternalLink } from 'react-external-link';

function Footer () {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-logo">
          <img src={ Logo } alt="The Glimpse Group" />
        </div>

        <div className="footer-contact">
          <div className="footer-grid">
            <div className="info__container">
              <i className="far fa-envelope"></i><a href="mailto:info@glimpsegroup.io" className='transform duration-300 hover:ease hover:text-gray-600'>info@glimpsegroup.io</a>
            </div>
            <div className="info__container">
              <i className="fas fa-map-marked-alt"></i>
              <p>
                15 West 38th Street, Floor 9<br />
                New York, New York 10018
              </p>
            </div>
            <div className="info__container whitespace-nowrap mr-2">
              <a href="tel:6092566622"><i className="fas fa-phone"></i></a>
              <p>General Inquiries: <a href="tel:6092566622" className='whitespace-nowrap transform duration-300 hover:ease hover:text-gray-600'>(609) 256-6622</a></p>
            </div>
            <div className="info__container whitespace-nowrap">
              <a href="tel:6094548030"><i className="fas fa-phone"></i></a>
              <p>Customer Support: <a href="tel:6094548030" className='whitespace-nowrap transform duration-300 hover:ease hover:text-gray-600'>(609) 454-8030</a></p>
            </div>
          </div>
        </div>

        <div className="footer-social">
          <div className="social-menu">
              <div><a href="https://www.facebook.com/glimpsegroup/" target="blank"><i className="fab fa-facebook" /></a>
              </div>

              <div><a href="https://twitter.com/GlimpseGroup" target="blank"><i className="fab fa-twitter" /></a>
              </div>

              <div><a href="https://www.linkedin.com/company/the-glimpse-group" target="blank"><i className="fab fa-linkedin-in" /></a>
              </div>
              
          </div>
          <div className='info-links' style={{ width: '160px' }}>
            <div className='flex justify-around' style={{ width: '160px' }}>
              <Link to='/faq' className='transform duration-300 hover:ease hover:text-gray-600'>FAQ </Link>
              <Link to="/privacy" className='transform duration-300 hover:ease hover:text-gray-600'>Privacy </Link>
            </div>
            <span>© 2022 by The Glimpse Group.</span>
          </div>
        </div>
      </div>
    </footer>
    
  )
};

export default Footer;