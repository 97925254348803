import React from "react";

function Dates({filter, dates}) {
  return (
      <div className="year-date">
        {
          dates.map((date, i) => {
            return <button
            className="btn p-2 m-2 w-20 "
            onClick={()=> filter (date)} key={i}>{date}
            </button>
          })
        }
      </div>
  )
}

export default Dates;