import Blog1 from '../../../assets/images/blog/construction_ar.jpeg'
import Blog2 from '../../../assets/images/blog/goat-black-friday-ar.jpeg'
import Blog3 from '../../../assets/images/blog/VR-AR-Developer-insider.png'
import Blog4 from '../../../assets/images/blog/Recruitment and Training.jpeg'
import Blog5 from '../../../assets/images/blog/How Immersive VR Can Help Nonprofit Organizations.jpeg'
import Blog6 from '../../../assets/images/blog/How Financial Institutions are Utilizing AR and VR.jpeg'
import Blog7 from '../../../assets/images/blog/Guest Enaggaemnt Using AR:VR.jpg'
import Blog8 from '../../../assets/images/blog/VR Enhances Business Analytics.jpeg'
import Blog9 from '../../../assets/images/blog/AR and VR Are Shaking Up The Restuarant Business.jpeg'
import Blog10 from '../../../assets/images/blog/Virtual Reality is Transforming Engineering.jpeg'
import Blog11 from '../../../assets/images/blog/VR in Education.jpeg'
import Blog12 from '../../../assets/images/blog/ Getting Into The Virtual Reality Industry.jpeg'
import Blog13 from '../../../assets/images/blog/VR Can Change Onboarding New Projects.jpeg'
import Blog14 from '../../../assets/images/blog/in Special Education.jpeg'
import Blog15 from '../../../assets/images/blog/jordan.png'
import Blog16 from '../../../assets/images/blog/Sustainable Packaging.jpeg'
import Blog17 from '../../../assets/images/blog/Marketing Agencies Can Harness VR.jpeg'
import Blog18 from '../../../assets/images/blog/JCollins-Post.png'
import blog_06_22_22 from '../../../assets/images/blog/softwareEngineer.png'
// import Blog19 from '../../../assets/images/news/blog-19.PNG'

import Dec1Year2021  from './SingleBlog/2021/Dec/Dec1'
import Nov2Year2021 from './SingleBlog/2021/Nov/Nov2';
import Nov28Year2021 from './SingleBlog/2021/Nov/Nov28';
import Aug20Year2021 from './SingleBlog/2021/Aug/Aug20';
import June1Year2021 from './SingleBlog/2021/June/June1';
import June17Year2021 from './SingleBlog/2021/June/June17';
import June24Year2021 from './SingleBlog/2021/June/June24';
import Apr1Year2021 from './SingleBlog/2021/Apr/Apr1';
import Apr7Year2021 from './SingleBlog/2021/Apr/Apr7';
import Apr21Year2021 from './SingleBlog/2021/Apr/Apr21';
import Apr22Year2021 from './SingleBlog/2021/Apr/Apr22';
import Mar26Year2021 from './SingleBlog/2021/Mar/Mar26';
import Mar1Year2021 from './SingleBlog/2021/Mar/Mar1';
import Feb25Year2021 from './SingleBlog/2021/Feb/Feb25';
import Feb25Blog2Year2021 from './SingleBlog/2021/Feb/Feb25Blog2';
import Feb22Year2021 from './SingleBlog/2021/Feb/Feb22';
import Feb18Year2021 from './SingleBlog/2021/Feb/Feb18';
import Feb18Blog2Year2021 from './SingleBlog/2021/Feb/Feb18Blog2';

import June22Year2022 from './SingleBlog/2022/June/June22'

const allblogs = [
  {
    id:10,
    category: 'June 2022',
    link: 'become-a-vr-ar-engineer',
    date: 'June 22, 2022',
    image: blog_06_22_22,
    mainTitle: 'How to Become a VR/AR Software Engineer in 2022',
    blogContent: <June22Year2022 />,
    next: 'ar-vr-construction'
  },
  {
    id: 11,
    category: 'December 2021',
    link: 'ar-vr-construction',
    date: 'December 1, 2021',
    image: Blog1,
    mainTitle: "How AR and VR Are Changing the Home Construction Industry",
    blogContent: <Dec1Year2021 />,
    next: 'black-friday-ar',
    prev: 'become-a-vr-ar-engineer'
  },
  {
    id: 12,
    category: 'November 2021',
    link: 'black-friday-ar',
    date: 'November 28, 2021',
    image: Blog2,
    mainTitle: 'How AR is Solving Black Friday’s Biggest Hurdle',
    blogContent: <Nov28Year2021 />,
    prev: 'ar-vr-construction',
    next: 'engineering-in-vr-ar',
  },
  {
    id: 13,
    category: 'November 2021',
    link: 'engineering-in-vr-ar',
    date: 'November 2, 2021',
    image: Blog3,
    mainTitle: 'Engineering in VR/AR: an Insider’s Perspective',
    blogContent: <Nov2Year2021 />,
    prev: 'black-friday-ar',
    next: 'ways-that-ar-and-vr-are-helping-society',
  },
  {
    id: 14,
    category: 'August 2021',
    link: 'ways-that-ar-and-vr-are-helping-society',
    date: 'August 20, 2021',
    image: Blog4,
    mainTitle: 'Ways That AR and VR are Helping Society',
    blogContent: <Aug20Year2021 />,
    prev: 'engineering-in-vr-ar',
    next: 'how-immersive-vr-can-help-nonprofit-organizations',
  },
  {
    id: 15,
    category: 'June 2021',
    link: 'how-immersive-vr-can-help-nonprofit-organizations',
    date: 'June 24, 2021',
    image: Blog5,
    mainTitle: 'How Immersive VR Can Help Nonprofit Organizations',
    blogContent: <June24Year2021 />,
    prev: 'ways-that-ar-and-vr-are-helping-society',
    next: 'how-financial-institutions-are-utilizing-ar-and-vr',
  },
  {
    id: 16,
    category: 'June 2021',
    link: 'how-financial-institutions-are-utilizing-ar-and-vr',
    date: 'June 17, 2021',
    image: Blog6,
    mainTitle: 'How Financial Institutions are Utilizing AR and VR',
    description: "With everything that happened in 2020, it’s no wonder that we find ourselves increasingly stepping into augmented or fully virtual realities (AR and VR). These tools, made popular through headsets like Oculus or Google Glass, are on the rise across industries. Now, even financial industries like big banks are applying AR and VR to great success.",
    blogContent: <June17Year2021 />,
    prev: 'how-immersive-vr-can-help-nonprofit-organizations',
    next: '5-ways-to-improve-guest-engagement-using-ar-vr',
  },
  {
    id: 17,
    category: 'June 2021',
    link: '5-ways-to-improve-guest-engagement-using-ar-vr',
    date: 'June 1, 2021',
    image: Blog7,
    mainTitle: '5 Ways to Improve Guest Enagagement Using AR/VR',
    blogContent: <June1Year2021 />,
    prev: 'how-financial-institutions-are-utilizing-ar-and-vr',
    next: 'vr-business-analytics',

  },
  {
    id: 18,
    category: 'April 2021',
    link: 'vr-business-analytics',
    date: 'April 22, 2021',
    image: Blog8,
    mainTitle: 'How VR Enhances Business Analytics',
    blogContent: <Apr22Year2021 />,
    prev: '5-ways-to-improve-guest-engagement-using-ar-vr',
    next: 'vr-ar-restaurant-shake-up',
  },
  {
    id: 19,
    category: 'April 2021',
    link: 'vr-ar-restaurant-shake-up',
    date: 'April 21, 2021',
    image: Blog9,
    mainTitle: 'How AR and VR Are Shaking Up The Restuarant Business',
    blogContent: <Apr21Year2021 />,
    prev: 'vr-business-analytics',
    next: 'vr-transforming-engineering',
  },
  {
    id: 20,
    category: 'April 2021',
    link: 'vr-transforming-engineering',
    date: 'April 7, 2021',
    image: Blog10,
    mainTitle: 'How Virtual Reality is Transforming Engineering',
    blogContent: <Apr7Year2021 />,
    prev: 'vr-ar-restaurant-shake-up',
    next: '6-inventive-examples-of-vr-in-education',
  },
  {
    id: 21,
    category: 'April 2021',
    link: '6-inventive-examples-of-vr-in-education',
    date: 'April 1, 2021',
    image: Blog11,
    mainTitle: '6 Inventive Examples of VR in Education',
    blogContent: <Apr1Year2021 />,
    prev: 'vr-transforming-engineering',
    next: 'vr-industry-quick-guide',
  },
  {
    id: 22,
    category: 'March 2021',
    link: 'vr-industry-quick-guide',
    date: 'March 26, 2021',
    image: Blog12,
    mainTitle: 'Quick Guide on Getting Into The Virtual Reality Industry',
    blogContent: <Mar26Year2021 />,
    prev: '6-inventive-examples-of-vr-in-education',
    next: '5-ways-vr-is-changing-onboarding',
  },
  {
    id: 23,
    category: 'March 2021',
    link: '5-ways-vr-is-changing-onboarding',
    date: 'March 1, 2021',
    image: Blog13,
    mainTitle: '5 Ways VR Can Change Onboarding New Projects',
    blogContent: <Mar1Year2021 />,
    prev: 'vr-industry-quick-guide',
    next: 'augmented-reality-and-special-education',
  },
  {
    id: 24,
    category: 'February 2021',
    link: 'augmented-reality-and-special-education',
    date: 'February 25, 2021',
    image: Blog14,
    mainTitle: 'How Augmented Reality Can Be Utilized in Special Education',
    description: "In the last few years, Virtual Reality (VR) has grown in popularity. Alongside it, Augmented Reality (AR) is becoming a popular way to add more digital information to reality without completely immersing the participant in an artificial environment. These technologies have recently expanded beyond the scope of gaming into more practical applications.",
    blogContent: <Feb25Year2021 />,
    prev: '5-ways-vr-is-changing-onboarding',
    next: 'glimpse-spotlight-jordan-gutt',
  },
  {
    id: 25,
    category: 'February 2021',
    link: 'glimpse-spotlight-jordan-gutt',
    date: 'February 25, 2021',
    image: Blog15,
    mainTitle: 'Glimpse Spotlight - Jordan Gutt',
    blogContent: <Feb25Blog2Year2021 />,
    prev: 'augmented-reality-and-special-education',
    next: 'augmented-reality-sustainable-packaging',
  },
  {
    id: 26,
    category: 'February 2021',
    link: 'augmented-reality-sustainable-packaging',
    date: 'February 22, 2021',
    image: Blog16,
    mainTitle: 'The Surprising Confluence of Augemented Reality and Sustainable Packaging',
    blogContent: <Feb22Year2021 />,
    prev: 'glimpse-spotlight-jordan-gutt',
    next: 'how-marketing-agencies-can-harness-vr-in-their-next-campaign',
  },
  {
    id: 27,
    category: 'February 2021',
    link: 'how-marketing-agencies-can-harness-vr-in-their-next-campaign',
    date: 'February 18, 2021',
    image: Blog17,
    mainTitle: 'How Marketing Agencies Can Harness VR in Their Next Campaign',
    blogContent: <Feb18Year2021 />,
    prev: 'augmented-reality-sustainable-packaging',
    next: 'glimpse-spotlight-jonathan-collins',
  },
  {
    id: 28,
    category: 'February 2021',
    link: 'glimpse-spotlight-jonathan-collins',
    date: 'February 18, 2021',
    image: Blog18,
    mainTitle: 'Glimpse Spotlight – Jonathan Collins',
    blogContent: <Feb18Blog2Year2021 />,
    prev: 'how-marketing-agencies-can-harness-vr-in-their-next-campaign',
  },

  
]

export default allblogs;