import React from 'react'
import { ExternalLink } from 'react-external-link';

function PressCoverageItems ({pressItem}) {
  return (
    <div className='card-grid-responsive'>
        {
          pressItem.map((item)=>{

              return <div className='card' key={item.id}>
              <div className='top-img'>
                <img src={ item.image } alt=""/>
              </div>
              <div className='card-content'>
                <h4>{item.title}</h4>
                <div className='card-content-info'>
                  <i className="far fa-calendar-alt"></i>
                  <span> { item.date }</span>
                  <i className="far fa-newspaper"></i>
                  <span> { item.coverageby }</span>
                </div>
                <div className='card-content-details'>
                
                  {item.description}
              
                </div>
                <ExternalLink href={item.link}>
                  <button className='btn'>Learn More</button>
                </ExternalLink>
              </div>
            </div>
          })
        }
    </div>
  )
}

export default PressCoverageItems;