import React from "react";

function June24Year2021 () {
    return (
      <React.Fragment>
        <p>Today, nonprofits, AKA non-governmental organizations (NGOs), face a challenge — to differentiate themselves from other organizations, and from the rest of the content online, fighting for people’s attention. People tend to respond better to issues they can identify with and that they feel closer to. Virtual Reality (VR) can bridge this gap in presenting different issues while incentivizing people to engage with them. 
        </p>
        <p>
        VR gives users the ability to control an avatar, which offers a unique feeling of connection to the world around them. In contrast to traditional media, VR elicits a higher sense of immersion in users. While we can still get absorbed when reading a book or watching a movie, VR blocks out our external environment and presents a substitute for reality. It affords users with a sense of “being there”.
        </p>
        <p>
        Previous research shows that participants’ behavior can be primed after VR experiences. Greater empathy and pro social behavior can be attained, although these effects are not always long term. Nonprofits can take advantage of this by creating stories that help participants understand particular issues better. With the following design principles, nonprofits can achieve greater immersion:
        </p>
        <h3>Customization</h3>
        <p>
        When given the opportunity, users can select certain characteristics to better embody and identify with an avatar. Users can then feel closer to a situation because they selected their own avatar. Depending on their avatar’s appearance, the participant’s behavior and experience can be altered. This is referred to as the Proteus Effect, where people who choose or are assigned a virtual persona begin to act accordingly to their looks. In this study, researchers examine the Proteus effect when applied to stereotypes, behavior, and avatar race.
        </p>
        <p>
        Customization can come in handy for nonprofits because participants choose how they want to be represented in the environment. They are placing themselves in the position of those they are trying to help. Glimpse’s subsidiary company, Foretell Reality, creates an environment based on empathy, intimacy, and attention for people seeking support. This can improve how nonprofits think about empathy in individual or group therapy.
        </p>
        <h3>Framing</h3>
        <p>
        Users rely on mental heuristics that aid them in going through large amounts of information. People tend to react more strongly to negative facts than to positive ones. Organizations need to frame urgent issues by explaining the consequences that inaction will have. More so, when consciously dealing with technologies rather than other social beings, people will reveal social interactions when computers “act” socially. It is important to use personal stories, rather than facts only, to help participants empathize with the problem. Even if the experience is virtual, people will most likely react as they would with a human being. In Clouds Over Sidra, participants follow Sidra, a 12-year-old girl living with her family in a refugee camp after having fled Syria. 
        </p>
        <h3>Interactivity</h3>
        <p>
        It is a hard concept to define and a harder one to grasp. When everything is dubbed as “interactive” in the industry, it then begs the question of what interactivity really means. Users in VR have the ability to modify the mediated environment and to exchange information with other participants, human or not. Interaction with another social being, even in the virtual world, can evoke a sense of companionship, empathy and encourage people to get involved. In one example, The National Autistic Society, launched a campaign to help people understand autism’s features better and how they can help. Rather than reading about autism or watching a video, participants can immerse themselves in an autistic person’s reality.
        </p>
        <h3>Feedback loops</h3>
        <p>
        They can help change behavioral habits by detecting certain behavior and storing it as data. By presenting it in a relevant way to the user, they are more likely to recognize patterns. Users can make a decision on whether they want to alter a specific behavior, when being presented with a possible course of action. By repeating these changes of behavior, users can create a new habit and understand the issue at hand better. VR is a great tool for educating people about a topic. By actually experiencing a problem first hand, potential donors recognize the process better and can feel closer to it afterwards. Glimpse subsidiary company, Adept XR engages learners in order to improve training experiences. By tracking people’s progress, you can train your own employees to understand a problem better.
        </p>
        <h3>Feedback loops</h3>
        <p>
        They can help change behavioral habits by detecting certain behavior and storing it as data. By presenting it in a relevant way to the user, they are more likely to recognize patterns. Users can make a decision on whether they want to alter a specific behavior, when being presented with a possible course of action. By repeating these changes of behavior, users can create a new habit and understand the issue at hand better. VR is a great tool for educating people about a topic. By actually experiencing a problem first hand, potential donors recognize the process better and can feel closer to it afterwards. Glimpse subsidiary company, Adept XR engages learners in order to improve training experiences. By tracking people’s progress, you can train your own employees to understand a problem better.
        </p>
        <h2>Conclusion</h2>
        <p>
        VR is not only used for entertainment or commercial purposes anymore. It can help your organization have a greater impact on your audience, increase donations, and educate people about your cause, and the solution, as well. 
        </p>
        <div className="border-top">
          <span className="author">
          Karime Harfush is a marketing intern with The Glimpse Group, based in Boston. She is currently a grad student at BU studying Emerging Media Studies. When she’s not working, she enjoys photography and reading.
          </span>
          <span className="disclaimer">
            Any content provided by our bloggers or authors are of their opinion. The views and opinions expressed are those of the authors and do not necessarily reflect the official policy or position of The Glimpse Group. In General The information provided by The Glimpse Group on https://www.theglimpsegroup.com/ (the “Site”) is for general information purposes only. All information on the Site is provided in good faith, however we make no representation or warranty, of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
          </span>
        </div>
        
      </React.Fragment>
    )
  }
  
  export default June24Year2021;