import React from "react";

function Aug20Year2021 () {
    return (
      <React.Fragment>
      <p>It’s interesting how often technology takes hold of society through entertainment. This has been the route for both augmented reality (AR) and virtual reality (VR). The technology has largely become popular through its association with immersive video games, or the growing world of virtual artwork and interactive filmmaking. Indeed, it’s fair to say the demand for immersive entertainment has helped to spur and fund the development of the technology in a positive way.  
      </p>
      <p>However, now that VR and AR are available in accessible forms, both in terms of practical applications and lower costs, the scope for its use as a tool in other areas has grown. Various industries are exploring the potential for compatible applications — from virtual conferencing to online real estate tours. Perhaps the most exciting aspects of this are how VR and AR can help our society become more positive, healthy, and successful.  

</p>
      <p>Let’s take a closer look at how both of these virtual technologies are making a difference in our world today, and will in the near future. 

</p>
      <h2>Recruitment and Training
</h2>
      <p>For a society to thrive it needs to be economically stable. An essential aspect in this is a healthy employment landscape; which makes efficient recruitment a vital tool for the community. Technology has already begun to play a role here. Applicant tracking systems (ATS) are now a prevalent presence, using artificial intelligence (AI) technology to examine resumes and identify the most appropriate candidates for interviews. This also means candidates need to create their applications with consideration toward communicating the skills and experience indicators companies and their AI will be looking for. However, while this provides hurdles for applicants to overcome, AR and VR are geared more towards assessment and support roles in recruitment. 

</p>
      <p>Both AR and VR can be effective tools for experiencing the workplace before actually starting work. To attract the candidates who have the right skills and are a good cultural fit, it is important to give potential workers a taste of the business atmosphere and the kind of tasks being performed. VR workplace tours are starting to become a reality, with the ecommerce platform Jet.com utilizing Samsung devices to give potential workers a feel for the environment. This is something we are likely to see more of as headsets that turn smartphones into VR devices are already in circulation, minimizing the need for specialist equipment during site visits or career fairs. 

</p>
      <p>Utilizing VR and AR in recruitment has also become essential in industries that require high health and safety standards of their workers. The logistics sector is among the industries engaging here. Virtual truck driver assessment and training is now a familiar part of testing the ability of candidates to act safely on the roads without putting them or the public in real danger. This is especially useful in specializations like chemical and hazardous material transportation. In military circles, the U.S. Army is currently utilizing AR to make initial training more swift and efficient. It equips recruits with Microsoft’s HoloLens AR headsets to place simulated scenarios and information in real-life environments. Indeed, as the Army has recently struck a deal with Microsoft to provide AR goggles for field use, it is likely the technology will play a key part in recruitment and training in the future. Adept XR uses VR to train individuals on expensive pharmaceutical equipment. The use of VR in this manner protects both the employees from injury and the equipment from damage.

</p>
      <h2>Rehabilitation</h2>
      <p>There will always be challenges that face us, both on an individual and community level. In some of these circumstances, we’ll find some form of rehabilitation is necessary to help members of our society overcome difficulties they’ve faced. AR and VR tools are being implemented to play an active and positive role in society more successfully.  

</p>
      <p>This is increasingly relevant for the rehabilitation of offenders. In the U.S., we have a very punishment-driven justice system. But there comes a point where we need to prepare those who have served their time to return to society. Without adequate guidance, former offenders may enter into the parole system, and through social difficulties or pressure commit a parole violation — arrests for a new crime and failing a drug test are among the most common here. This could see citizens having their parole revoked and being returned to prison, setting in motion a cycle of offending and imprisonment that can be negative for many involved. However, some prisons are starting to use VR as part of pre-release programs. These are often directed at those who have spent a significant period of time in prison and are not used to the challenges of the outside world. VR gives them exposure to basic tasks — using a microwave, navigating grocery stores, just being out among the public. Supported with instruction, these can be powerful tools for reintegration. 

</p>
      <p>Out in the world, VR is also playing a crucial role in the treatment and rehabilitation of those experiencing mental health challenges. Foretell Reality offers VR therapy sessions, both group and individual, to patients. Psychologists are utilizing programs that safely and immersively introduce those who are suffering from PTSD into challenging scenarios. This allows patients to understand their triggers, and both develop and practice coping mechanisms.  

</p>
      <h2>Design</h2>
      <p>We don’t often think of design as an essential aspect of our society, but it is integral to almost everything we do. The tools you use every day, the home you live in, even the safety information you see around the workplace is the result of design concepts. As such, we must use tools to make sure designers can perform their tasks efficiently and creatively. Both VR and AR are contributing to making design better.   
</p>
      <p>In some ways, AR has become a forum through which designers better understand the products they’re creating. During presentations and the iteration stage of development, stakeholders can share 3D images of design concepts on handheld devices, rather than rely on 2D images. This allows designers to fully explore the look and functionality of an item, and identify practical issues that may not have been so readily identified before the prototyping and tooling stages.

</p>
      <p>From an architectural perspective, VR is helping designers and engineers to explore buildings before they are built. This is particularly useful when seeking to understand the practical functionality of a property — designers can walk through and easily establish whether people will be able to navigate the building, or where problems for those with limited mobility might lie. As there are also VR sculpting programs on the market, designers may soon be able to make real-time alterations to design as they’re touring it. 

</p>
    <h2>Conclusion</h2>
      <p>As AR and VR are becoming more affordable and accessible, their ability to have a positive impact is growing. The platforms are helping to find the right candidates for jobs and can be used to train them safely. There is also increasing scope as a tool for social and health rehabilitation. The technologies even streamline how products are designed. The more we can explore and experiment with these tools, the greater effect they can have upon our society.

</p>
      <div className="border-top">
      <span className="author">
      Luke Smith is a writer and researcher turned blogger. Since finishing college he is trying his hand at being a freelance writer. He enjoys writing on a variety of topics but technology topics are his favorite. When he isn’t writing you can find him traveling, hiking, or gaming.
      </span>
      <span className="disclaimer">
        Any content provided by our bloggers or authors are of their opinion. The views and opinions expressed are those of the authors and do not necessarily reflect the official policy or position of The Glimpse Group. In General The information provided by The Glimpse Group on https://www.theglimpsegroup.com/ (the “Site”) is for general information purposes only. All information on the Site is provided in good faith, however we make no representation or warranty, of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
      </span>
      </div>

      </React.Fragment>
    )
  }
  
export default Aug20Year2021;