import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../assets/images/glimpseblue.svg'


function Page404 () {
    return (
      <div className='Page404 pt-24'>
        <div className='main-content flex-col items-center'>
          <div>
            <img src={ Logo } style={{ width: '400px' }} alt='The Glimpse Group'/>
          </div>
          <div className='flex-col items-center w-full'>
            <h1 className='text-center'>404</h1>
            <h2 className='text-center'>UH OH! You're lost.</h2>
            <p className='text-center'>The page you are looking for does not exist. Use the button below to return to the homepage.</p>
            <div className='flex justify-center'>
              <Link to='/'>
                <button className='btn m-3'>
                  Return Home
                </button>
              </Link>
            </div>
            
         </div>
        </div>
      </div>
    )
}

export default Page404;