import React from "react";

function Dec1 () {
    return (
      <React.Fragment>
        <p>Anyone who has spent any time in the home construction industry knows that, while the job can be sweet, it can also be brutal. The housing market has always been volatile, but perhaps never more so than now. And that trend is only expected to continue, as home prices rise and inventories dwindle.</p>
        <p>That means that if you’re going to thrive in the increasingly competitive housing construction industry, you’re going to need an edge. For a growing number of residential construction firms, that competitive edge rests in the use of augmented reality (AR) and virtual reality (VR). But how, exactly, are AR and VR being used in the home construction industry?</p>
        <h2>Seeing the Unseen</h2>
        <p>One of the most challenging aspects of home construction is helping prospective homebuyers visualize what the home may become. After all, it’s not easy to market a home that hasn’t even been built yet. 
        <p>
        But with virtual reality, homebuyers can simulate a complete walk-thru of the home prior to its construction. By using augmented reality services such as Auggd, they can get a 3D image of the proposed construction superimposed on the site itself. 
        </p>
        <p>
        That can be a profoundly important tool when you’re trying to sell a home that doesn’t yet exist.  Buyers aren’t just looking for a place to live. They’re looking for an experience and a lifestyle. 
        </p>
        <p>
        AR/VR technologies help prospective homeowners experience not only what it would be like to be inside the space, but also outside of it. When buyers choose a home, for instance, they want to understand not just the interior amenities, but also the exterior features of the property, from parking access to outdoor living and recreation spaces. 
        </p>
        <p>
        With AR, in particular, prospective buyers can experience how the home will nestle into its outdoor surroundings. They can visualize what it would be like to pull into the drive or play with the kids in the backyard.</p>
        </p>
        <h2>The Power of Home-Making</h2>
        <p>The biggest drawback, perhaps, of traditional floorplans, blueprints, and schematics is that it can be difficult for potential buyers to truly imagine themselves living in the space.  This is the reason why home staging has become such an important tool for realtors.
        </p>
        <p>
        But, of course, you can’t stage a home that hasn’t been built. 
        </p>
        <p>
        Cue AR/VR. With these enhanced reality devices, buyers can experiment with different furnishings and color palettes. Images of family photos and other personal memorabilia may even be included in the simulation. All these help prospective buyers truly get a sense of the space as their future home. 
        </p>
        <p>
        Even more exciting, these tools can allow future homeowners to play with their decor even as the home itself is under construction. Decorating your home, especially a first one, is the pivotal first step in making it your own. 
        </p>
        <p>
        But decorating can lead to costly and time-consuming mistakes, such as when the colors you choose just don’t look right in the space, or a piece of furniture isn’t the right size for the room. With AR/VR, buyers can test out a seemingly infinite number of paints, furnishings, and fixtures. 
        </p>
        <p>
        Using enhanced reality tools, future homeowners can direct home builders in the buildout and finishing of the home. 
        </p>
        <br/>
        <p>
        And what that means, ultimately, is that home builders will grow their roster of satisfied clients, enabling them to build their brand reputation as the builder of choice, the builder who delivers extraordinary results for an increasingly discriminating target market.
        </p>
        <h2>Making It Work</h2>
        <p>As suggested above, AR/VR technologies can be an enormous resource for maximizing profitability and efficiency in home construction because they enable home plans to be marketed before their construction. This not only helps drive consumer interest but also better enables home builders to identify potential points of concern with the construction plan. 
        <p>
        For example, architects and engineers can use the tools to test the functionality of the home design. This can be especially important when building for homebuyers with special needs, such as seniors or individuals with disabilities. 
        </p>
        <p>
        For example, architects can use VR to simulate navigating the home from a wheelchair. This would enable them to identify prospective issues that might never have been apparent in traditional design methods, such as recognizing when hallways are too narrow for wheelchair-users to navigate, light switches too high to reach, or roll-in shower stalls blocked by poorly placed sinks or toilets.</p>
        </p>

        <h2>The Takeaway</h2>
        <p>Augmented and virtual reality (AR/VR) isn’t just for gamers anymore. Now, more than ever, the technologies are being integrated into industries far and wide, from education to healthcare. But their promise is particularly apparent in the home construction industry. With these tools, homes can be marketed before they’re even built. At the same time, home builders can harness input from target clients to craft custom homes that exceed homebuyers’ best expectations. Best of all, architects, engineers, and builders can test their plans to ensure that design strategies meet their clients’ needs</p>

        <div className="border-top">
        <span className="author">
          Luke Smith is a writer and researcher turned blogger. Since finishing college he is trying his hand at being a freelance writer. He enjoys writing on a variety of topics but technology topics are his favorite. When he isn’t writing you can find him traveling, hiking, or gaming.
        </span>
        <span className="disclaimer">
          Any content provided by our bloggers or authors are of their opinion. The views and opinions expressed are those of the authors and do not necessarily reflect the official policy or position of The Glimpse Group. In General The information provided by The Glimpse Group on https://www.theglimpsegroup.com/ (the “Site”) is for general information purposes only. All information on the Site is provided in good faith, however we make no representation or warranty, of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
        </span>
        </div>

      </React.Fragment>
    )
}

export default Dec1;