import React from 'react'

function Feb18Blog2Year2021 () {
  return (
    <React.Fragment>
      <p>Virtual Reality is ever-evolving, and in order to figure out the logistics between development and release to the public, there is a lot of testing involved. Enter Jonathan Collins, a Digital Marketing and Strategy Professional at Foretell Reality, a Glimpse Group subsidiary. He ensures that everything stays on track, and reaches out to people to try and get people to test out their products and get feedback.</p>
      <p>Join us as we ask him questions about his experience with the Glimpse Group, where he sees the AR/VR world going, and more!</p>
      <h2>Foretell Reality isn’t your first time working for a company creating virtual environments. You’ve previously worked at other companies where you’ve focused on virtual worlds, social experiences, and cutting-edge technologies. What drew you to want to work in the virtual space? </h2>
      <p>
        <strong>Answer:</strong>
        I started working in the ‘Virtual Worlds’ space about 15 years ago. At that point (pre-Facebook), there was a theory that the web would actually transform into a 3D metaverse and many companies and brands were exploring the space. Platforms like Second Life were growing exponentially based on interest from millions of people who wanted to explore a world that was developing both rapidly and organically. What drew me to it was a long held questioning of what it means to be ‘real.’  Virtual worlds and now VR push the boundary of ‘real’ and that is fascinating to me.
      </p>
      <h2>How did you first hear about Foretell Reality and what made you want to join this Glimpse subsidiary company? </h2>
      <p>
        <strong>Answer:</strong>
        Coincidence to some degree. I live in the same town as one of the co-founders of Glimpse, DJ Smith. We have mutual friends and I contacted him in the context of attending one of the VR Meetups he was organizing. From there, I learned about his connection to Glimpse and I expressed an interest in Foretell since it focuses on social interactions in VR which has a lot of similarities to my earlier experiences with virtual worlds.
      </p>
      <h2>How would you describe Foretell Reality?</h2>
      <p>
        <strong>Answer:</strong>
        Foretell Reality is a social VR platform that includes tools and features designed to facilitate lifelike remote human communication. We intentionally leave the possibilities open as to how these features and tools are used and instead focus on co-developing experiences with our partners. Examples of use cases include group support and therapy, soft skills development, and business collaboration, but we are always open to new ideas.
      </p>
      <h2>What do you do as the Director of Partnerships and Strategy for Foretell?</h2>
      <p>
        <strong>Answer:</strong>
        Since we work closely with our partners to develop experiences in VR that support their needs, Partnerships and Strategy often go hand in hand. As an example, we see great promise in VR throughout the addiction recovery process from support groups to exposure therapy to withdrawal distraction and mindfulness training. Strategically, this has lead me to reach out to those in the recovery field to gauge interest, understand their needs, and explain and hopefully demo our platform. As we move forward with some of these partners, I put back on the Partnership hat and work to ensure that we develop experiences that support their needs.
      </p>
      <h2>Where do you think Foretell Reality will fit into the future of VR and interpersonal experiences?</h2>
      <p>
        <strong>Answer:</strong>
        We will be a competitive option for anyone looking to leverage social VR for creating better experiences and outcomes for their end-users. We will continue to differentiate ourselves by working closely with our partners to develop tools and features accordant with their needs. I’m excited to see where that can go.
      </p>
      <h2>When you’re not in VR, what is something you like to do for fun?</h2>
      <p>
        <strong>Answer:</strong>
        Sadly I’m not in VR as much as I’d like because I’m writing emails, meeting with partners, or strategizing with our product team. So for me, being in VR is actually one of my fun activities. Specifically, I’ve been boxing with my brother-in-law in Creed which gives me a great workout and the satisfaction of KO’ing a close family member.   
      </p>
      <h2>How would you describe Foretell Reality in one word?</h2>
      <p>
        <strong>Answer:</strong>
        That’s really tough. I guess I’ll say ‘togetherness.’
      </p>
      <div className="border-top">
      <span className="author">
        Jonathan Collins is the Glimpse Group’s subsidiary Foretell Reality‘s own Director of Partnerships and Strategy. He has had a lot of experience in working with virtual environments and is excited to put that work to the test with Foretell Reality. Foretell Reality is one of 8 Glimpse Group subsidiaries and one that focuses on developing VR for many different uses, from therapeutic environments to collaboration within enterprises. Contact Foretell Reality here.
      </span>
      <span className="disclaimer">
        Any content provided by our bloggers or authors are of their opinion. The views and opinions expressed are those of the authors and do not necessarily reflect the official policy or position of The Glimpse Group. In General The information provided by The Glimpse Group on https://www.theglimpsegroup.com/ (the “Site”) is for general information purposes only. All information on the Site is provided in good faith, however we make no representation or warranty, of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
      </span>
      </div>
    </React.Fragment>
  )
}

export default Feb18Blog2Year2021;