import React from "react";
import map from '../../../../../../assets/images/blog/mappingImage.png'

function June22Year2022() {

  return (
    <React.Fragment>
      <p>
        Do you like working with cutting-edge technology? Is reality boring and 
        you’re convinced you could make a better one? Then a career as a VR/AR 
        engineer may be for you!
      </p>
      <p>
        VR and AR technology, and the industry surrounding it, have both rapidly 
        advanced since 2020. Major tech players have invested billions into 
        immersive tech, and <a className='text-blue-400' href="https://www.nytimes.com/2021/10/28/technology/facebook-meta-name-change.html#:~:text=Facebook's%20name%20change%20is%20largely,original%20brand%20name%20of%20Oculus.">some even rebranded accordingly</a>. While some things 
        have remained constant, many aspects of the industry have changed 
        significantly over the past two years. Here's how you can break into the 
        industry as a VR/AR engineer in 2022.

      </p>

      <h2 className='mb-4'>Terminology: VR, AR, XR, MR, and the Metaverse</h2>
      
      <p>
        Although VR and AR have fully entered the public consciousness in recent years, 
        it’s still helpful to clearly define what's what in the world of immersive 
        technology. Software engineers in this field usually specialize in a particular 
        platform or device, so it’s important to know exactly what each specialization 
        offers. Moreover, new terms like XR, MR, and the metaverse have joined the 
        lexicon alongside VR and AR. Understanding these terms should help you decide 
        which direction you want to move toward in the expanding VR/AR industry.
      </p>

      <h3>Virtual Reality (VR)</h3>
      <p>
        Virtual Reality is a fully immersive digital environment simulating a 360° 
        physical space. Users interact with virtual assets using equipment such as a 
        VR headset or haptic gloves. VR offers full-scale visual immersion for the user.
      </p>

      <h3>Augmented Reality (AR)</h3>
      <p>
        Augmented Reality enhances the physical world with digital elements. Unlike VR 
        which fabricates an entire virtual environment, AR superimposes graphics, audio 
        cues, and text on real world objects. AR creates a composite view of physical 
        environments and virtual content.
      </p>

      <h3>Mixed Reality (MR)</h3>
      <p>
        Mixed Reality blends physical and virtual elements to create a seamless, 
        interactive environment. MR is often mistaken for an extension of Augmented 
        Reality, but it is a bit more complex than that. Instead of overlaying digital 
        content on physical objects like AR, Mixed Reality merges virtual and physical 
        worlds into a single cohesive experience.
      </p>

      <h3>Extended Reality (XR)</h3>
      <p>
        Extended Reality is simply the concept of enhancing our reality with technology. 
        XR describes how we use technology to create unique experiences and environments. 
        It is essentially an umbrella term encompassing VR, AR, and MR, all of which 
        transform the physical world in various ways.
      </p>

      <h3>Metaverse</h3>
      <p>
        The metaverse is a simulated 3D environment based on VR, AR, and blockchain technology 
        combined with aspects of social media. It is an immersive virtual space where people 
        interact, socialize, and conduct business via their own personalized avatars. XR 
        technology aims to bring users into a virtual world, and that virtual world is the metaverse.
      </p>

      <h2 className='mb-4'>VR/AR Today: Technology and Industry</h2>
      <p>
        If you want to start a career as a VR/AR engineer, it is also important to understand the 
        current state of the VR/AR industry. The industry has grown dramatically over the past two 
        years, supported by significant advancements in VR/AR technology. It’s not uncommon for tech
         and industry to expand in tandem, so getting a general sense of both the technology and the 
         industry today is crucial for starting your career as a VR/AR developer. 
      </p>

      <p>
        The major technological advancements revolve around size and affordability. Just as computers 
        used to occupy an entire room and now fit in your pocket, VR/AR technology is being sized down 
        for practical consumer use. <a className='text-blue-400' href="https://citrusbits.com/how-is-apples-lidar-technology-a-game-changer-for-ar-apps/">LiDAR</a>, for example, was historically "too bulky" and "too expensive" 
        for widespread adoption, but companies like Apple are now incorporating LiDAR into their mobile 
        devices to enhance AR capabilities.
      </p>

      <p>
        VR headsets are becoming similarly accessible, both physically and fiscally. DJ Smith, CCO and 
        co-founder of The Glimpse Group, noted that the most surprising technological development is 
        "having [the Oculus Quest 2] in my hand for $299." Affordability is a crucial requirement for 
        mass adoption, and VR/AR hardware's newfound accessibility is perhaps the most impressive 
        development in recent years. "Even in 2018, you needed a $2500 computer and a wired headset," 
        DJ observed, but today there are self-contained wireless VR headsets available for a reasonable price.” 
      </p>

      <p>As the tech has advanced, the VR/AR industry has expanded in lockstep. The <a className='text-blue-400' href="https://www.xrtoday.com/mixed-reality/what-is-extended-reality/">XR market</a>, valued at 
        $26.05 billion in 2020, is expected to reach a value of $463.7 billion by 2026. Major tech 
        corporations such as <a className='text-blue-400' href="https://arinsider.co/2021/12/20/spatial-beats-meta-google-xr-funding/">Meta, Google</a>, and <a href="https://arinsider.co/2021/12/20/spatial-beats-meta-google-xr-funding/">Apple</a> 
        are pouring resources into XR development and <a className='text-blue-400' href="https://www.theverge.com/23022611/meta-facebook-nazare-ar-glasses-roadmap-2024">poaching XR talent</a> from one another, driving up the value of XR development skills. </p>

      <p>Non-tech businesses are quickly becoming aware of XR's importance as well. According to one survey, 
      <a className='text-blue-400' href="https://www.thedrum.com/profile/meta/news/ar-vr-opportunities-for-brands"> 75% of business leaders </a> 
         plan to incorporate VR and AR tech into their business plans by 2023. The pandemic foregrounded XR technology 
        as a way to bridge physical distance for employees and consumers alike, further accelerating development.
        Business leaders across every industry are realizing the importance of incorporating XR technology going forward. 
        This means skyrocketing demand for VR/AR engineers, software developers, UX designers, and other associated roles. 
      </p>

      <h2 className='mb-4'>Insights from VR/AR Developers</h2>
      <p>
        In order to create a step-by-step approach for starting a career in VR/AR development, I sat down with Ollie 
        Morland and Woodrow Proctor, two VR software engineers at Glimpse. I asked them about their professional 
        journey toward XR development, their experience in the job market, and their current position at Glimpse. 
        Together, we outlined the skills, choices, and pathways leading to a career as a VR/AR engineer.
      </p>
      <p>
        To start, every developer needs to learn a coding language and a platform. Usually, these skills are tied to 
        one another: each VR/AR device uses a particular platform, and each platform uses a particular coding language. 
        The easiest way to start is by deciding what you want to do as a VR/AR engineer. Ollie, Woodrow, and I created
         a flowchart below which should help you get started. 
      </p>
      <img src={map} className='w-full md:w-9/12' style={{ height: 'auto' }} />

      <p>
        The device you choose tells you which coding languages and platforms you should become fluent in. For example, 
        if you're interested in developing AR apps for iPhones, you should learn Swift and download Xcode.
      </p>
      
      <p>
        The main takeaway here is that most AR devices use a unique coding language and platform, while the majority of 
        VR devices use the Unity platform. Note that the flowchart isn't a comprehensive list of all the languages and 
        platforms a VR/AR programmer could benefit from; there are plenty of devices not mentioned above which use 
        different languages and platforms. These are simply the broadest categories for VR/AR development to get you started. 
      </p>

      <p>
        Now that you know what device, coding language, and platform you want to learn, the next step is deciding how you 
        want to learn. The Glimpse Group employs dozens of VR/AR software engineers hailing from a range of professional 
        backgrounds. Our engineers acquired their programming skills in different ways, all of which are perfectly 
        acceptable to any VR/AR employer. 
      </p>

      <p>The most obvious way to learn is by getting a relevant college degree. A degree in computer science, programming, 
      graphical design, or any other related field will provide you with the foundation you need to then specialize in 
      VR or AR development. Some colleges such as <a className='text-blue-400' href="https://em.online.engineering.nyu.edu/professional-certificate-ar-vr?utm_source=Google&utm_network=g&utm_medium=c&utm_term=ar%20degree&utm_location=9004347&utm_campaign=B-9999_US_GG_SE_NYPCXR_MVP&utm_content=AR_Other&gclid=Cj0KCQjwr-SSBhC9ARIsANhzu14wpBDqlkqLXvVybFxRFivD7KWzOIR0DsiCazHlLT-Cq5jQYh8Ttc0aAlKWEALw_wcB">NYU</a>, 
      <a className='text-blue-400' href="https://drexel.edu/westphal/academics/undergraduate/virtual-reality/"> Drexel University</a>, and 
      <a className='text-blue-400' href="https://xrcourses.professionalstudies.syracuse.edu/courses/xr-development-with-unity?utm_source=adword&utm_medium=cpc&utm_campaign=Dev+Syllabus&utm_content=SYR+VR+Dev&utm_term=b_vr%20development&utm_source=adwords&utm_term=vr%20development&utm_campaign=UCR&utm_medium=ppc&hsa_mt=b&hsa_ad=590753976925&hsa_net=adwords&hsa_src=g&hsa_kw=vr%20development&hsa_tgt=kwd-325183237834&hsa_cam=16507870978&hsa_acc=3318234811&hsa_ver=3&hsa_grp=133666556105&gclid=Cj0KCQjwr-SSBhC9ARIsANhzu15EksLe5JT0sZ6mnQBTQ0tBrZYQZ9WUqB5ELZERBDkXnPTasxjuCdkaAn5eEALw_wcB"> Syracuse University </a> 
      even offer specific VR, AR, and immersive media programs, ranging from ten week certification courses to full-fledged 
      four-year degrees.</p>

      <p>College degrees are the most conventional route toward an XR development career, but the tuition and time commitment 
      required can form major barriers to entry. XR development bootcamps serve as a more affordable and expedient alternative. 
      Non-university programs like XR Terra teach you the basics of XR development over a four to eight week course. 
      <a className='text-blue-400' href="https://www.xrterra.com/"> XR Terra</a> offers programs for varying levels of expertise, from 
      <a className='text-blue-400' href="https://www.xrterra.com/programs/xr-foundations-developer/"> novice coders</a> looking for an introduction to 
      <a className='text-blue-400' href="https://www.xrterra.com/ar-industry-bootcamps/"> more experienced developers</a> looking to specialize their skills. 
      These bootcamp programs are part-time and usually conducted in the evening, leaving plenty of room for a job or university 
      courses during the daytime. Woodrow first started with XR Terra’s <a className='text-blue-400' href="https://www.xrterra.com/vr-industry-bootcamps/">“VR Industry Bootcamp: Developer” </a> 
      program, and now he’s a full time VR developer at Glimpse. </p>

      <p>Finally, there’s a third route that’s even more affordable – and more challenging – than a college degree or a bootcamp. 
        You can simply learn by doing. This is how Ollie came to XR development: he had a semi-related degree in electrical 
        engineering, but knew he had a penchant for computer programming. He downloaded SDKs and tinkered with software 
        development in his free time, all while applying to any job at a VR/AR company, not necessarily just a developer job. 
        As Ollie explained, “If you want to be a chef, but don’t have cooking experience, start washing dishes and learn through 
        your surroundings.” While the autodidactic route is potentially the most challenging, if you can learn through osmosis and
        bootstrap your own development skills, you will come out the other end a well-rounded developer and an impressive job 
        candidate.</p>

      <p>
        But as we all know, getting hired isn’t just about having the right skillset. Building connections with businesses and 
        individuals in the industry is also a crucial part of landing an XR development job. As you’re acquiring your development 
        skills, try joining some of the many VR/AR communities online! Whether you’re reaching out to fellow developers for tips 
        and tricks, chatting with XR enthusiasts on the forums, or messaging company reps on LinkedIn, integrating yourself into 
        the world of VR/AR is essential for kickstarting your development career. 
      </p>
      
      
      <h2 className='mb-4'>Conclusion</h2>
      <p>
        The job market for XR developers is booming. Major tech companies are investing heavily in XR development and non-tech 
        companies are looking to incorporate XR tech into their platform. This means huge demand for those with the necessary 
        skills and far more XR development jobs than ever before. 
      </p>
      <p>
        If you’re interested in a career in VR/AR, start off by deciding your specialty. Do you want to develop VR or AR 
        programs? And for which device? From there, you’ll determine which coding language and platform you should become 
        familiar with. There’s plenty of ways to do so, from conventional college programs to XR developer bootcamps to 
        independent learning. Then you can start applying to internships and job openings at any of the several companies 
        who are in need of XR developers. With valuable professional skills and an expanding job market, the future is bright 
        for XR developers in 2022.
      </p>

      <p>Interested in interning with The Glimpse Group as a VR/AR developer? Send a resume and cover letter to 
      info@glimpsegroup.io.</p>
      
      <div className="border-top">
        <span className='author'>
          Cameron is the Content Writer/Editor at The Glimpse Group. As a former 
          academic researcher in the humanities, he blends his outside perspective 
          as a relative newcomer to tech with Glimpse's industry-leading expertise 
          to demystify the world of VR, AR, and the metaverse. 
        </span>
        <span className="disclaimer">
          Any content provided by our bloggers or authors are of their opinion.
          The views and opinions expressed are those of the authors and do not
          necessarily reflect the official policy or position of The Glimpse
          Group. In General The information provided by The Glimpse Group on 
          <a className="text-blue-400" href="https://www.theglimpsegroup.com/"> https://www.theglimpsegroup.com/ </a>(the “Site”) is for general
          information purposes only. All information on the Site is provided in
          good faith, however we make no representation or warranty, of any
          kind, express or implied, regarding the accuracy, adequacy, validity,
          reliability, availability or completeness of any information on the
          Site.
        </span>
      </div>
    </React.Fragment>
  );
}

export default June22Year2022;
