import React, { useState } from 'react';
import allreleases from '../../Components/data/Releases/allreleases'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";


function SingleRelease () {

  const [numPages, setNumPages] = useState(null);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { slug } = useParams();

  return (
<div className="SingleRelease">
      <Helmet>
        <title>Press Release | The Glimpse Group</title>
        <meta
          name="description"
          content="The Glimpse Group is a Virtual Reality & Augmented Reality Platform Company Comprised of Multiple Software & Services Subsidiaries Creating VR/AR Solutions"
        />
      </Helmet>
      {allreleases.filter(item => item.slug === slug).map((item, index) => (
      <center key={`${item.id}${index}`}>
        <div >
          <Document file={item.pdfPage} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(
              new Array(numPages),
              (el,index) => (
                <Page  scale={1.65}
                  key={`page_${index+1}`}
                  pageNumber={index+1}
                />
              )
            )}
          </Document>
        </div>
      </center>
     ))}
    </div>
  )
}

export default SingleRelease;