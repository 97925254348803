import React from 'react'

function PrevNext(props) {
    const next = props.nextPost
    const prev = props.prevPost
    
    
    return (
        <div className='w-full flex justify-center'>
            <div className='flex'>
                {prev &&
                    <a href={"/news/glimpse-blog/" + prev} className='m-3'>
                        <button className='btn'>
                            Previous Post
                        </button>
                    </a>
                }
                {next &&
                    <a href={"/news/glimpse-blog/"+ next} className='m-3'>
                        <button className='btn'>
                            Next Post
                        </button>
                    </a>
                }
            </div>
        </div>
    )
}

export default PrevNext
