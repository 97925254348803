import React from "react";

function Feb22Year2021 () {
    return (
      <React.Fragment>
      <h2>So What is Sustainable Packaging Anyway?</h2>
      <p>
      Sustainable packaging is the process of packing your product in a way that is eco-conscious. Some advantages for this would be helping the environment, having your brand being considered eco-friendly, and can save on shipping costs as eco-friendly packaging is traditionally more lightweight. However, eco-friendly packaging can cost more to manufacture, and keeping track of which suppliers are eco-friendly can become a logistical nightmare. One simple solution is to cut down on the amount of plastic used in your product packaging. You can additionally pay attention to your suppliers, and consider moving towards recycled materials and more green suppliers if they aren’t up to your sustainable standards.
      </p>
      <h2>Why is AR the Solution?</h2>
      <p>
      In a study by Glimpse Group subsidiary QReal and Oxford University, it was found that viewing food in AR can actually increase craveability for the food, and increase the likelihood that the customer will ultimately purchase more of that product. The same conclusions can be applied to other industries. AR is a great way to let customers try out your product, and it lets them have an experience that they couldn’t otherwise have. For businesses, this looks like a higher quantity and quality of interactions with their brand. For customers, it represents a chance to try out the products prior to purchase.
      </p>
      <h2>Why invest in AR solutions?</h2>
      <p>
      By saving money on packaging and instead funding AR, you get a positive interaction and a touchpoint with customers, all under the umbrella of cheaper, more sustainable packaging. Between 2014 and 2018, eco-conscious packaging sales increased by 20%. This is just one of many times that eco-conscious packaging has been very beneficial to companies, along with saving money on shipping costs and providing a good selling point for your product, and by combining it with AR, you are bound to create the ultimate customer experience.
      </p>
      <p>
      Investors are also an audience that can be appeased through the use of AR and sustainable packaging. Many investors look for sustainability and eco-conscious practices when considering where to invest their money. This means that you can make your business stand out to not only customers but also investors by having eco-friendly practices.
      </p>
      <p>
      When all is said and done, sustainable packaging isn’t just the nice thing to do, it is becoming the standard. And when mixed with AR, it gives a business the opportunity to stand out in ways that are not traditionally available. Sustainable practices with AR will not only look good to your customers but to your investors too. Take the opportunity to become a leader, and use AR and sustainable practices to revolutionize your product packaging today!
      </p>
      
      <div className="border-top">
      <span className="author">
      Eric Bachman is a marketing intern with the Glimpse Group based near Seattle, Washington. His passion for marketing is only matched by his love for learning. He spends free time with his friends and family and is often found on the tennis court playing with them.
      </span>
      <span className="disclaimer">
        Any content provided by our bloggers or authors are of their opinion. The views and opinions expressed are those of the authors and do not necessarily reflect the official policy or position of The Glimpse Group. In General The information provided by The Glimpse Group on https://www.theglimpsegroup.com/ (the “Site”) is for general information purposes only. All information on the Site is provided in good faith, however we make no representation or warranty, of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
      </span>
      </div>
      </React.Fragment>
    )
  }
  
  export default Feb22Year2021;