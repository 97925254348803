import { createTheme, responsiveFontSizes } from "@material-ui/core";

const glimpseBlue = "#539cd2";
const glimpseAlt = "#00699B";
const glimpseLight = "#D3F4FF";

let theme = createTheme({
  palette: {
    common: {
      blue: `${glimpseBlue}`,
      black: "#333",
    },
    primary: {
      main: "#000",
      light: "#fff",
    },
    secondary: {
      main: `${glimpseAlt}`,
      light: `${glimpseLight}`,
    },
    background: {
      paper: "#fafafa",
    },
  },
  typography: {
    tab: {
      // fontFamily: "'Judson', serif",
      fontSize: "0.95rem",
      fontWeight: "700",
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
