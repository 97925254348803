import React from "react";
import { Link } from "react-router-dom";

function BlogItems({ blogItem }) {
  return (
    <div className="card-grid-responsive">
      {blogItem.map(item => {
        return (
          <div className="card" key={item.id}>
            <div className="top-img">
              <img src={item.image} alt={item.title} />
            </div>
            <div className="card-content">
              <h4>{item.mainTitle}</h4>
            </div>
            <Link to={`/news/glimpse-blog/${item.link}`}>
              <button className="btn">Read</button>
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export default BlogItems;
