import React from "react";

function Febr25 () {
    return (
      <React.Fragment>
      <p>
      Customer Experience is everything. It is how the customer will interact with your company and is inevitably the impression that your company will have on the customer. Jordan Gutt is the Customer Experience Manager here at the Glimpse Group, and today is his time in the Glimpse Spotlight!
      </p>
      <p>
      Join us as we ask him questions about his experience with the Glimpse Group, where he sees the AR/VR world going, and more!
      </p>
      <h2>You are a recent college graduate who is making great strides when it comes to Customer Experience and Quality Assurance at The Glimpse Group. How did you first hear about Glimpse and what made you want to work here?</h2>
      <p>
      Answer: I first found the Glimpse Group while job searching.  After graduating from Boston University in May of 2019, and just returning from a trip to Israel, I decided to check out the Boston University VR/AR Club Facebook page.  There I saw a recent post from the president of the club saying he had just finished an internship at the Glimpse Group and was offering to forward resumes from anyone in the club to DJ Smith (Glimpse Group Co-Founder).
      </p>
      <p>
      The moment I viewed their website I was really intrigued by their organizational structure: a company with subsidiary companies each focused on different industry categories of VR/AR.  It made a lot of sense to me, especially in an emerging field like VR/AR.  The opportunity to work with various companies in an emerging industry sounded really cool to me and I knew I would be able to absorb so much knowledge as a quality assurance intern.
      </p>
      <h2>Why did you want to pursue a career with immersive technologies?</h2>
      <p>
      Answer: Pre-COVID, during a VR/AR Club meeting I tried for the first time the Microsoft HoloLens.  Having no controllers and using your hand to navigate the UI, along with the AR graphics, really impressed me.  From that moment on I knew VR/AR was going to be a big deal and only keep getting more advanced so I started learning everything I could about it.
      </p>
      <p>
      I realized the technology was still relatively new, especially to consumers, but as an entrepreneur, I saw a lot of potential value and many different use cases for the new type of media. I am so happy I found the Glimpse Group because I not only get to work with various different teams of extremely talented engineers and designers, but also get to be involved in building the greater VR/AR industry.
      </p>
      <h2>What is quality assurance (QA)? Why is it important for VR or AR experience development?</h2>
      <p>
      Answer: QA is the process of testing software to make sure it is free of software “bugs” or malfunctions and make sure the code is working as intended.  QA is an essential step in the software development process spanning across mobile, web, and VR/AR products and services.  During the QA process the QA analysts and testers find and record bugs in an experience and report them back to the developers so they can fix them.  The value that QA brings is having another pair of eyes scrutinize the appearance and usability of an app outside of the team that made it.
      </p>
      <p>
      QA is not just checking to see if things are working right and looking good, but also making sure things make sense from an end user’s point of view.  When checking a VR or AR experience, we have to make sure there is nothing in the experience that breaks the sense of immersion a VR or AR experience provides to the user.  For example, are there any floating chairs? Does the user interface make sense when completing a task?  Do the shadows look right in the virtual environment?
      </p>
      <p>
      QA is especially important for VR and AR experiences because the two mediums are extremely new to people.  When our end users enter our experiences, most of the time it is their first encounter with the new technology.  Completing many rounds of QA, with a diverse set of testers, helps ensure the end user’s experience is the best that it can be, leaving them with a positive impression of the emerging media.
      </p>
      <h2>From QA-ing experiences, you have since evolved to become Glimpse’s first-ever Customer Experience Manager. Why should companies care about Customer Experience?</h2>
      <p>
      Answer: When it comes down to it, customer experience is everything.  One thing that separates Amazon from the rest of the companies in the world is their relentless focus on customer experience.  Since people have limited time and limited attention you have to make things as effortless as possible for them.
      </p>
      <p>
      In an emerging industry like VR/AR, a lot of times people do not know where to begin to integrate this technology into their organization.  The role of the customer service department is to make it as easy and seamless as possible to integrate VR or AR into our client’s organization’s existing communication or learning systems. The Oculus Quest 2, as a standalone mobile VR device, has made getting up and running in VR quite seamless.  The price point on these devices has also made it extremely accessible for consumers to try and has become a great option for businesses looking to integrate VR into their operations.
      </p>
      <h2>What type of impact do you hope to bring as Glimpse’s first CX Manager?</h2>
      <p>
      Answer: The impact that I hope to bring as Glimpse’s first CX Manager is to set the expectation for people, whether it be our client themself, our client’s employees, or the general public, that VR is fun, easy, and fulfilling.
      </p>
      <p>
      Since VR/AR is still new to consumers and enterprises it is extremely important to listen to our customers’ feedback and deliver them a quality product they can rely on.  Working backwards from the customer’s needs and gathering their feedback along the way is something I would like to engrain in our customer experience department.  My goal is to help get our products to a point where they are so consistently good that there is no real need for a dedicated customer support person.
      </p>
      <h2>Describe The Glimpse Group in one word.</h2>
      <p>
      Answer: Synergy: The Glimpse Group is a well balanced ecosystem that dynamically facilitates continuous innovation in an emerging industry.  The Glimpse Group not only provides the ideal conditions for entrepreneurs to succeed in an emerging industry like VR/AR, but also enables a platform for collaboration, discussion, and innovation.
      </p>
      <div className="border-top">
      <span className="author">
      Jordan Gutt is the Glimpse Group’s own QA specialist and CX manager. You can reach out to him if you have any further questions on his Linkedin, or you can contact the Glimpse Group here.
      </span>
      <span className="disclaimer">
        Any content provided by our bloggers or authors are of their opinion. The views and opinions expressed are those of the authors and do not necessarily reflect the official policy or position of The Glimpse Group. In General The information provided by The Glimpse Group on https://www.theglimpsegroup.com/ (the “Site”) is for general information purposes only. All information on the Site is provided in good faith, however we make no representation or warranty, of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
      </span>
      </div>
      </React.Fragment>
    )
  }
  
  export default Febr25;