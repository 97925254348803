import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, AccordionSummary, IconButton } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'; 
import { Link as SmoothLink } from 'react-scroll';
import { ExternalLink } from 'react-external-link';
// import Menu from '@material-ui/core/Menu';
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
// import { useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import logo from "../assets/images/glimpseblue.svg";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';


import { BiLinkExternal } from "@react-icons/all-files/bi/BiLinkExternal";

function ElevationScroll(props) {
	const { children } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}

const useStyles = makeStyles(theme => ({
  hideBorder: {
    "&.MuiAccordion-root:before": {
      display: "none",
    },
    "&.MuiAccordion-root": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
  },

  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "0.75rem",
  },

  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  logo: {
    width: "10rem",
    height: "7rem",
    padding: '.5em',
  },

  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  Button: {
    borderRadius: "10px",
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.common.blue,
    fontSize: "1rem",
    padding: "0 1rem",
    height: "49px",
    width: 'auto',
    whiteSpace: 'nowrap',
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  menu: {
    backgroundColor: theme.palette.common.black,
    zIndex: 1302,
  },

  menuItem: {
    whiteSpace: "unset",
    wordBreak: "word-break",
    ...theme.typography.tab,
    opacity: 0.7,
    maxWidth: "230px",

    "&:hover": {
      opacity: 1,
    }
  },
  drawerIcon: {
    height: "50px",
    width: "50px",
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
    drawer: {
      backgroundColor: theme.palette.common.white,
    },
  },
  drawerItem: {
    opacity: 0.7,
  },

  drawerItemSelected: {
    "& .MuiListItemText-root": {
      opacity: 1,
    },
  },

  drawerItemContact: {
    width: "85%",
    marginLeft: "18px",
    marginTop: "3rem",
    textAlign: "center",
    borderRadius: "10px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.blue,
  },

  listWidth: {
    width: "250px",
  },

  mContact: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.common.blue,
    fontSize: "1.25rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery('(max-width:1213px)')
  const [openDrawer, setOpenDrawer] = useState(false);
  const [value, setValue] = useState(0);
  const [onHoverIndex, setOnHoverIndex] = React.useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const gaEventTracker = useAnalyticsEventTracker('Header');

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleClick = (e, index) => {
    setAnchorEl(e.currentTarget);
    setOnHoverIndex(index);
    setOpenMenu(true);
  };

  const handleMenuItemClick = (e, i) => {
    setAnchorEl(null);
    setOpenMenu(false);
    setSelectedIndex(i);
  };

  const handleClose = e => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  window.onscroll = function() {scroll()};

  function scroll(){
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      document.getElementById("logo").style.width = "6.66rem";
      document.getElementById("logo").style.height = "4rem";
    } else {
      document.getElementById("logo").style.width = "11.66rem";
      document.getElementById("logo").style.height = "7rem";
    } 
  }

  const menuOptions = React.useMemo(
    () => [
      {
        name: "Glimpse Blog",
        typeLink: Link,
        link: "/news/glimpse-blog",
        activeIndex: 3,
        selectedIndex: 0,
      },

      {
        name: "Glimpse Insider",
        typeLink: Link,
        link: "/news/glimpse-insider",
        activeIndex: 3,
        selectedIndex: 1,
      },

      {
        name: "Press Coverage",
        typeLink: Link,
        link: "/news/press-coverage",
        activeIndex: 3,
        selectedIndex: 2,
      },

      {
        name: "Press Releases",
        typeLink: Link,
        link: "/news/press-releases",
        activeIndex: 3,
        selectedIndex: 3,
      },
    ],
    []
  );

  const routes = React.useMemo(
    () => [
      {
        name: "Home",
        typeLink: Link,
        link: "/",
        activeIndex: 0,
        class: classes.tab,
        disable: false,
        target: "_self",
        ariaOwns: anchorEl ? "simple-menu-ind" : undefined,
        ariaPopup: anchorEl ? "true" : undefined,
        mouseOver: handleClick,
        subRoute: null,
      },
      {
        name: "About Glimpse",
        typeLink: Link,
        link: "/about-glimpse",
        activeIndex: 1,
        class: classes.tab,
        disable: false,
        target: "_self",
        ariaOwns: anchorEl ? "simple-menu-ind" : undefined,
        ariaPopup: anchorEl ? "true" : undefined,
        mouseOver: handleClick,
        subRoute: null,
      },
      {
        name: "Industry Solutions",
        typeLink: Link,
        link: null,
        activeIndex: 2,
        disable: true,
        // class: classes.tab,
        selectedIndex: 0,
        ariaOwns: anchorEl ? "simple-menu-ind" : undefined,
        ariaPopup: anchorEl ? "true" : undefined,
        mouseOver: handleClick,
        subRoute: [
          {
            name: "AEC",
            typeLink: Link,
            link: "/industry-solutions/architecture-engineering-construction",
            target: "_self",
            disable: false,
            activeIndex: 2,
            selectedIndex: 0,
          },

          {
            name: "K-12 Education",
            typeLink: Link,
            link: "/industry-solutions/k12-education",
            target: "_self",
            activeIndex: 2,
            disable: false,
            selectedIndex: 1,
          },

          {
            name: "Higher Education",
            typeLink: Link,
            link: "/industry-solutions/higher-education",
            target: "_self",
            activeIndex: 2,
            disable: false,
            selectedIndex: 2,
          },

          {
            name: "Food & Hospitality",
            typeLink: Link,
            link: "/industry-solutions/food-hospitality",
            target: "_self",
            activeIndex: 2,
            disable: false,
            selectedIndex: 3,
          },

          {
            name: "Professional Training",
            typeLink: Link,
            link: "/industry-solutions/professional-training",
            target: "_self",
            activeIndex: 2,
            disable: false,
            selectedIndex: 4,
          },

          {
            name: "Data Visualization",
            typeLink: Link,
            link: "/industry-solutions/finance-data-visualization",
            target: "_self",
            activeIndex: 2,
            disable: false,
            selectedIndex: 5,
          },

          {
            name: "Healthcare",
            typeLink: Link,
            link: "/industry-solutions/healthcare",
            target: "_self",
            activeIndex: 2,
            disable: false,
            selectedIndex: 6,
          },

          {
            name: "Marketing",
            typeLink: Link,
            link: "/industry-solutions/marketing",
            target: "_self",
            activeIndex: 2,
            disable: false,
            selectedIndex: 7,
          },

          {
            name: "Events & Meetings",
            typeLink: Link,
            link: "/industry-solutions/events-and-meetings",
            target: "_self",
            activeIndex: 2,
            disable: false,
            selectedIndex: 8,
          },
        ],
      },
      {
        name: "News",
        typeLink: Link,
        link: "/news/press-releases",
        activeIndex: 3,
        class: classes.tab,
        target: "_self",
        disable: true,
        ariaOwns: anchorEl ? "simple-menu-ind" : undefined,
        ariaPopup: anchorEl ? "true" : undefined,
        mouseOver: handleClick,
        subRoute: [
          {
            name: "Press Releases",
            typeLink: Link,
            link: "/news/press-releases",
            target: "_self",
            activeIndex: 3,
            disable: false,
            selectedIndex: 0,
          },
          {
            name: "Glimpse Blog",
            typeLink: Link,
            link: "/news/glimpse-blog",
            target: "_self",
            activeIndex: 3,
            selectedIndex: 1,
            disable: false,
          },
          //{
          //  name: "Glimpse Insider",
          //  typeLink: Link,
          //  link: "/news/glimpse-insider",
          //  activeIndex: 3,
          //  selectedIndex: 2,
          //},
          {
            name: "Press Coverage",
            typeLink: Link,
            link: "/news/press-coverage",
            target: "_self",
            activeIndex: 3,
            selectedIndex: 2,
            disable: false,
          },
        ],
      },
      {
        name: "Our Work",
        typeLink: Link,
        link: "/our-work",
        target: "_self",
        activeIndex: 4,
        disable: false,
        class: classes.tab,
        ariaOwns: anchorEl ? "simple-menu-ind" : undefined,
        ariaPopup: anchorEl ? "true" : undefined,
        mouseOver: handleClick,
        subRoute: null,
      },
      {
        name: "Our Companies",
        typeLink: Link,
        link: "/our-companies",
        target: "_self",
        activeIndex: 5,
        disable: false,
        class: classes.tab,
        ariaOwns: anchorEl ? "simple-menu-ind" : undefined,
        ariaPopup: anchorEl ? "true" : undefined,
        mouseOver: handleClick,
        subRoute: null,
      },
      {
        name: (
          <div className='flex'>
            Investor Relations
            <div className='whitespace-nowrap flex items-center'>
              <BiLinkExternal className='ml-1' />
            </div>
          </div>
        ),
        target: "_blank",
        typeLink: ExternalLink,
        eLink: "https://ir.theglimpsegroup.com/",
        activeIndex: 6,
        class: classes.tab,
        disable: false,
        ariaOwns: anchorEl ? "simple-menu-ind" : undefined,
        ariaPopup: anchorEl ? "true" : undefined,
        mouseOver: handleClick,
        subRoute: null,
      },
    ],
    [anchorEl, classes]
  );

  useEffect(() => {
    [...menuOptions, ...routes].forEach(route => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (value !== route.activeIndex) {
            setValue(route.activeIndex);
            if (route.selectedIndex && route.selectedIndex !== selectedIndex) {
              setSelectedIndex(route.selectedIndex);
            }
          }
          break;
        default:
          break;
      }
    });
  }, [value, menuOptions, selectedIndex, routes]);

  {/* 
  const tabs = (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabContainer}
        indicatorColor="primary"
      >
        {routes.map((route, index) => (
          route.disable ? 
            <Tab
              // disabled
              disableRipple
              key={`${route}${index}`}
              className="transition duration-500 transform hover:-translate-y-1 hover:text-base font-normal flex whitespace-nowrap text-gray-400 hover:text-gray-900 bg-transparent" //, route.class}
              style={{ transform: 'scaleY(1.2)', fontFamily: 'Montserrat', minWidth: 10, fontSize:'.75rem', cursor: 'default' }}
              //component={route.typeLink}
              // to={route.link}
              // href={route.eLink}
              // target={route.target}
              label={route.name}
              // aria-owns={route.ariaOwns}
              // aria-haspopup={route.ariaPopup}
              onClick={ev => route.mouseOver(ev, route.activeIndex)}
              onMouseOver={ev => route.mouseOver(ev, route.activeIndex)}
              onMouseLeave={() => setOpenMenu(false)}
              expandIcon={<ExpandMoreIcon />}
            />
          : 
            <Tab
              disableRipple
              key={`${route}${index}`}
              className="transition duration-500 transform flex whitespace-nowrap hover:text-base hover:text-blue-900" //, route.class}
              style={{ transform: 'scaleY(1.2)', fontFamily: 'Montserrat', minWidth: 10, fontSize:'.75rem' }}
              component={route.typeLink}
              to={route.link}
              href={route.eLink}
              target={route.target}
              label={route.name}
              aria-owns={route.ariaOwns}
              aria-haspopup={route.ariaPopup}
              onMouseOver={ev => route.mouseOver(ev, route.activeIndex)}
              onMouseLeave={() => setOpenMenu(false)}
            />
        ))}
      </Tabs>

      <Button
        variant="contained"
        className={classes.Button}
        component={SmoothLink}
        style={{ fontFamily: 'Montserrat'}}
        to="contact"
        smooth="true"
        durantion={900}
        
      >
        Contact Us
      </Button>

      <Popper
        open={openMenu}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              borderRadius: "5px",
            }}
          >
            <Paper className={{ root: classes.menu }} elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  onMouseOver={() => setOpenMenu(true)}
                  onMouseLeave={handleClose}
                  autoFocusItem={false}
                  id="simple-menu"
                  disablePadding
                  onKeyDown={handleListKeyDown}
                >
                  {routes[onHoverIndex].subRoute?.map((option, index) => (
                    <MenuItem
                      key={`${index}${option}`}
                      component={option.typeLink}
                      smooth="true"
                      to={option.link}
                      href={option.eLink}
                      target="_self"
                      classes={{ root: classes.menuItem }}
                      onClick={event => {
                        handleMenuItemClick(event, index);
                        setValue(option.activeIndex);
                        handleClose();
                      }}
                      selected={
                        index === selectedIndex && value === option.activeIndex
                      }
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
  */}
  
  const topBar = (
    <div className='w-10/12 flex justify-center bg-transparent'>
      <nav className="navbar navbar-expand-lg bg-transparent p-0 w-full" style={{ padding: '0px'}}>
        <div className="collapse navbar-collapse w-full" id="navbarSupportedContent">
          <ul className="flex w-full justify-center p-0">
            <li className={`nav-item ${location.pathname === "/" ? "active" : ""} transition duration-500 transform hover:-translate-y-1 font-light`} style={{ fontFamily: 'Montserrat' }}>
              <a className="nav-link py-2.5 pr-2.5" href="/">Home</a>
            </li>
            <li className={`nav-item ${location.pathname === "/about-glimpse" ? "active" : ""} transition duration-500 transform hover:-translate-y-1 font-light`} style={{ fontFamily: 'Montserrat' }}>
              <a className="nav-link p-2.5" href="/about-glimpse">About Glimpse</a>
            </li>
            <li className={`nav-item dropdown ${location.pathname === "/industry-solutions/architecture-engineering-construction" || 
            location.pathname === "/industry-solutions/k12-education" || 
            location.pathname === "/industry-solutions/higher-education" || 
            location.pathname === "/industry-solutions/food-hospitality" || 
            location.pathname === "/industry-solutions/professional-training" ||
            location.pathname === "/industry-solutions/finance-data-visualization" ||
            location.pathname === "/industry-solutions/healthcare" ||
            location.pathname === "/industry-solutions/marketing" ||
            location.pathname === "/industry-solutions/events-and-meetings" ? "active" : ""} transition duration-500 transform hover:-translate-y-1 font-light`} style={{ fontFamily: 'Montserrat' }}>
              <a className="nav-link p-2.5 dropdown-toggle" href="#" id="solutions" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Industry Solutions
              </a>
              <div className="dropdown-menu" aria-labelledby="solutions" style={{ marginTop: '-1.1em', width: '200px' }}>
                {/* <div class="dropdown-divider"></div> */}
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/architecture-engineering-construction" ? "active" : ""}`} href="/industry-solutions/architecture-engineering-construction">AEC</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/k12-education" ? "active" : ""}`} href="/industry-solutions/k12-education">K-12 Education</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/higher-education" ? "active" : ""}`} href="/industry-solutions/higher-education">Higher Education</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/food-hospitality" ? "active" : ""}`} href="/industry-solutions/food-hospitality">Food & Hospitality</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/professional-training" ? "active" : ""}`} href="/industry-solutions/professional-training">Professional Training</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/finance-data-visualization" ? "active" : ""}`} href="/industry-solutions/finance-data-visualization">Data Visualization</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/healthcare" ? "active" : ""}`} href="/industry-solutions/healthcare">Healthcare</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/marketing" ? "active" : ""}`} href="/industry-solutions/marketing">Marketing</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/industry-solutions/events-and-meetings" ? "active" : ""}`} href="/industry-solutions/events-and-meetings">Events & Meetings</a>
              </div>
            </li>
            <li className={`nav-item dropdown ${location.pathname === "/news/press-releases" || location.pathname === "/news/glimpse-blog" || location.pathname === "/news/press-coverage" ? "active" : ""} transition duration-500 transform hover:-translate-y-1 font-light`} style={{ fontFamily: 'Montserrat' }}>
              <a className="nav-link p-2.5 dropdown-toggle" href="#" id="news" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                News
              </a>
              <div className="dropdown-menu" aria-labelledby="news" style={{ marginTop: '-1.1em' }}>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/news/press-releases" ? "active" : ""}`} href="/news/press-releases">Press Releases</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/news/glimpse-blog" ? "active" : ""}`} href="/news/glimpse-blog">Glimpse Blog</a>
                <a className={`dropdown-item hover:bg-blue-300 text-sm ${location.pathname === "/news/press-coverage" ? "active" : ""}`} href="/news/press-coverage">Press Coverage</a>
              </div>
            </li>
            <li className={`nav-item ${location.pathname === "/our-work" ? "active" : ""} transition duration-500 transform hover:-translate-y-1 font-light`} style={{ fontFamily: 'Montserrat' }}>
              <a className="nav-link p-2.5" href="/our-work">Our Work</a>
            </li>
            <li className={`nav-item ${location.pathname === "/our-companies" ? "active" : ""} transition duration-500 transform hover:-translate-y-1 font-light`} style={{ fontFamily: 'Montserrat' }}>
              <a className="nav-link p-2.5" href="/our-companies">Our Companies</a>
            </li>
            <li className="nav-item">
              <a className="nav-link py-2.5 pl-2.5 transition duration-500 transform hover:-translate-y-1 font-light" style={{ fontFamily: 'Montserrat' }} href="https://ir.theglimpsegroup.com/" target='_blank'>
              <div className='flex'>
                Investor Relations
                <div className='whitespace-nowrap flex items-center'>
                  <BiLinkExternal className='ml-1' />
                </div>
              </div>
              </a>
            </li>
            
          </ul>
        </div>
      </nav>
    </div>
  )

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        // className={{paper: classes.drawer}}
      >
        <div className={classes.toolbarMargin} />
        <List
          disablePadding
          className={[classes.listWidth, classes.hideBorder]}
        >
          {routes.map((route, index) =>
            route.subRoute !== null ? (
              <Accordion
                elevation={0}
                className={classes.hideBorder}
                key={index}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {route.name}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="column">
                    {route.subRoute.map((route, index) => (
                      <Grid item key={index}>
                        <ListItem
                          divider
                          button
                          component={route.typeLink}
                          to={route.link}
                          href={route.eLink}
                          target="_self"
                          selected={value === route.activeIndex}
                          classes={{ selected: classes.drawerItemSelected }}
                          onClick={() => {
                            setOpenDrawer(false);
                            setSelectedIndex(route.selectedIndex);
                          }}
                        >
                          <ListItemText
                            className={classes.drawerItem}
                            disableTypography
                          >
                            {route.name}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ) : (
              <ListItem
                divider
                key={index}
                button
                component={route.typeLink}
                to={route.link}
                href={route.eLink}
                target="_self"
                selected={value === route.activeIndex}
                classes={{ selected: classes.drawerItemSelected }}
                onClick={() => {
                  setOpenDrawer(false);
                  setSelectedIndex(route.selectedIndex);
                }}
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  {route.name}
                </ListItemText>
              </ListItem>
            )
          )}
          <ListItem
            onClick={() => {
              setOpenDrawer(false);
              setValue(5);
            }}
            divider
            button
            smooth="true"
            durantion={900}
            component={SmoothLink}
            classes={{
              root: classes.drawerItemContact,
              selected: classes.drawerItemSelected,
            }}
            to="contact"
            selected={value === 5}
          >
            <ListItemText
              className={classes.mContact}
              component={SmoothLink}
              to="contact"
              onClick={()=>gaEventTracker('contact_drawer')}
              disableTypography
            >
              Contact Us
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer, "focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-opacity-10"}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ElevationScroll className='p-0'>
        <AppBar position="fixed" color="default" className='p-0' style={{ zIndex: "999" }} id='header'>
          <Toolbar variant="regular" style={{ padding: "0 1em 0 0" }}>
            <div className='flex items-center justify-between w-screen p-0' >
              <Button
                component={Link}
                to="/"
                disableRipple
                onClick={() => setValue(0)}
                className={classes.logoContainer}
              >
                <img alt="company logo" id='logo' className='transform duration-500 ease h-28' src={logo} />
              </Button>
              
              {matches ? drawer : topBar }

              {!matches && 
                <Button
                  variant="contained"
                  className="whitespace-nowrap bg-blue-500 pt-2 pb-2 pl-3 pr-3 text-white"

                  component={SmoothLink}
                  style={{ fontFamily: 'Montserrat', backgroundColor: 'rgb(35 130 218)'}}
                  to="contact"
                  smooth="true"
                  onClick={()=>gaEventTracker('contact_head')}
                  durantion={900}
                  
                >
                  Contact Us
                </Button>
              
              }

              <Popper
                open={openMenu}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                      borderRadius: "5px",
                    }}
                  >
                    <Paper className={{ root: classes.menu }} elevation={0}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          onMouseOver={() => setOpenMenu(true)}
                          onMouseLeave={handleClose}
                          autoFocusItem={false}
                          id="simple-menu"
                          disablePadding
                          onKeyDown={handleListKeyDown}
                        >
                          {routes[onHoverIndex].subRoute?.map((option, index) => (
                            <MenuItem
                              key={`${index}${option}`}
                              component={option.typeLink}
                              smooth="true"
                              to={option.link}
                              href={option.eLink}
                              target="_self"
                              classes={{ root: classes.menuItem }}
                              onClick={event => {
                                handleMenuItemClick(event, index);
                                setValue(option.activeIndex);
                                handleClose();
                              }}
                              selected={
                                index === selectedIndex && value === option.activeIndex
                              }
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
              
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
}
