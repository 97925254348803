import React, { useEffect } from 'react';
import ReleaseItems from '../../Components/PressReleaseItems';
import releases from '../../Components/data/Releases/allreleases';
import Dates from '../../Components/Dates'
import { Helmet } from "react-helmet";
// import Logo from '../../assests/images/glimpseblue.png';
// import { ExternalLink } from 'react-external-link';
import { useState } from 'react';

const allDates = [...new Set(releases.map(item => item.year)), 'All'];

function PressReleases () {
  const [dates] = useState(allDates);
  const [releaseItems, setReleaseItems] = useState(releases);
  const [date, setDate] = useState('Start')

  const filter = (dateIn) =>{
    if(dateIn === 'All'){
        setReleaseItems(releases)
        setDate('All')
        return;
    }
    let filteredData;
    if (dateIn === 'Start'){
      filteredData  = releases.filter((item)=>{
        return item.year === '2022';
      })
    } else {
      filteredData  = releases.filter((item)=>{
        setDate(date)
        return item.year === dateIn;
      })
    }
    setReleaseItems(filteredData);

}
  useEffect(() => {
    if(date === 'Start'){
      let releases2022 = releaseItems.filter((item) => {
        return item.year === '2022';
      })
      setReleaseItems(releases2022)
    }
  }, [date])

  return (
    <div className="PressR">
      <Helmet>
        <title>Press Releases | Official Glimpse announcements | Investor news</title>
        <meta
          name="description"
          content="Read official announcements from The Glimpse Group. Stay up to date on breaking news and important company developments."
        />
      </Helmet>
      <h1 className="m__bottom m__top text-gray-600 font-light" style={{ fontFamily: 'Montserrat' }}>Press Releases</h1>
      <Dates filter={filter} dates={dates}/>
      <div className="press-content mt-3">
        <ReleaseItems releaseItem={releaseItems}/>
      </div>
    </div>
  )
}

export default PressReleases;