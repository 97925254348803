import React from 'react'
import { Link } from 'react-router-dom'

function ReleaseItems ({releaseItem}) {
  return (
    <div className='card-grid-responsive'>
        {
          releaseItem.map((item, index)=>{

              return <div className='card' key={`${item.id}${index}`}>
              <div className='top-img'>
                <img src={ item.image } alt=""/>
              </div>
              <div className='card-content'>
                  <h4>{item.title}</h4>
                  <div className='card-content-date'>
                    <i className="far fa-calendar-alt"></i>
                    <span> { item.date }</span> 
                  </div>
              </div>
              <Link to={`/news/press-releases/${item.slug}`}>
                <button className='btn'>Learn More</button>
              </Link>
            </div>
          })
        }
    </div>
  )
}

export default ReleaseItems;