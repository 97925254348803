import React from 'react'
import BlogItems from '../../Components/BlogItems';
import blogs from '../../Components/data/Blogs/allblogs'
import { useState } from 'react'
import { Helmet } from 'react-helmet';


//  
function Blogs () {
  // const [categories] = useState(allCategories);
  // const [blogItems, setBlogItems] = useState(blogs);
  const [blogItems] = useState(blogs);

  // const filter = (category) =>{
  //       if(category === 'All Blogs'){
  //           setBlogItems(blogs)
  //           return;
  //       }
  //       const filteredData  = blogs.filter((item)=>{
  //           return item.category === category;
  //       })
  //       setBlogItems(filteredData);
  //   }

  return (
    <div className='Blog m__top'>
      <Helmet>
        <title>VR and AR Insights | Industry news and key trends</title>
        <meta
          name="description"
          content="Insightful and thought-provoking articles on the world of VR, AR, and the metaverse, according to industry experts."
        />
      </Helmet>
          <h1 className='m__bottom'>Glimpse Blog</h1>        
          <BlogItems blogItem={blogItems}/>
    
          {/* <div className='blog-categories'>
          <div class="search">
            <input type="text" className="searchTerm" placeholder="What are you looking for?" />

              <button type="submit" class="searchButton">
                <i class="fa fa-search"></i>
            </button>
          </div>
            <h3>Archives</h3>
            <Categories filter={filter} categories={categories} />
          </div> */}
    </div>
  )
}


export default Blogs;