import React, {useRef, useEffect} from 'react'

const VideoJS = () => {
  const ref = useRef(null);
  useEffect(() => {
    const video = ref.current;
    video.playbackRate = 0.5;
    
  }, []);

  return (
      <video id='video' ref={ref} autoPlay muted loop playsInline preload="auto">
        <source src="https://i.imgur.com/dnogOPY.mp4" />
      </video>      
  )
}

export default VideoJS;