import { Grid, Paper } from "@material-ui/core";
import React, {useState} from "react";
import emailjs from 'emailjs-com';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';

function Contact() {
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [org, setOrg] = useState();
  const [message, setMessage] = useState();
  const [submitted, setSubmitted] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker('Contact us');

  const bSmall = useMediaQuery('(max-width:450px)')// theme.breakpoints.down("md"));

  const sitekey = process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC
  //const recaptchaRef = React.createRef();

  function handleEmailChange(event){
    setEmail(event.target.value)
  }

  function handleFirstChange(event){
    setFirstName(event.target.value)
  }

  function handleLastChange(event){
    setLastName(event.target.value)
  }

  function handleOrgChange(event){
    setOrg(event.target.value)
  }

  function handleMessageChange(event){
    setMessage(event.target.value)
  }

  function handleSubmit(event) {
    event.preventDefault();
    //console.log('test', event.target);
    let gResponse = document.getElementById('g-recaptcha-response')
    if (gResponse.value) {
      emailjs.sendForm('service_d1x8ss7', 'template_sls03f4', event.target, 'ZTQJioF16AnG3aZEk')
      .then((result) => {
          console.log(result.text);
          gaEventTracker('contact_form_submitted')
      }, (error) => {
          console.log(error.text);
      });
      setSubmitted(true)
      setEmail('')
      setFirstName('')
      setLastName('')
      setOrg('')
      setMessage('')
    } else {
      alert('reCAPTCHA is required')
    }
    
  }
    
  return (
    <div className="contact" id="contact">
      <div className="text__center">
        {/* <p>CONTACT</p> */}
        <h2 className='font-light text-gray-600 pb-clr m-2' style={{ fontFamily: 'Montserrat' }}>Contact Us</h2>
      </div>

      <Paper elevation={6} className="contact-box" style={{ borderRadius: 15 }}>
        <div className="contact-form">
          <form
            name="insightly_form"
            data-form-id="8602"
            id="contact-form"
            // action="?"
            // method="post"
            encType="text/plain"
            onSubmit={ handleSubmit }
          >
            <Grid container spacing={2} className='flex flex-col items-center content-center'>
              <div className='flex'>
                <Grid item xs={6} className='mx-2'>
                  <div className='my-2'>
                    <label htmlFor="EMAIL_ADDRESS" className='mb-0'>Email*</label>
                    <input
                      id="insightly_EMAIL_ADDRESS"
                      name="email"
                      type="text"
                      value={email} 
                      onChange={handleEmailChange}
                      autoComplete="off"
                      required
                    />
                  </div>

                  <div className='my-2'>
                    <label htmlFor="FIRST_NAME" className='mb-0'>First Name*</label>
                    <input
                      id="insightly_FIRST_NAME"
                      name="firstName"
                      type="text"
                      value={firstName} 
                      onChange={handleFirstChange}
                      autoComplete="off"
                      required
                    />
                  </div>

                  <div className='my-2'>
                    <label htmlFor="LAST_NAME" className='mb-0'>Last Name*</label>
                    <input
                      id="insightly_LAST_NAME"
                      name="lastName"
                      type="text"
                      value={lastName} 
                      onChange={handleLastChange}
                      autoComplete="off"
                      required
                    />
                  </div>

                  <div className='my-2'>
                    <label htmlFor="ORGANISATION_NAME" className='whitespace-nowrap mb-0'>Organization Name*</label>
                    <input
                      id="insightly_ORGANISATION_NAME"
                      name="org"
                      type="text"
                      value={org} 
                      onChange={handleOrgChange}
                      autoComplete="off"
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={6} >
                  <div className='my-2'>
                    <label htmlFor="Message__c" className='mb-0'>Message*</label>
                    <textarea
                      id="insightly_Message"
                      name="message"
                      type="text"
                      rows={8}
                      value={message} 
                      onChange={handleMessageChange}
                      autoComplete="off"
                      required
                    />
                  </div>
                  {/* Does this reference somewhere?  */}
                  <div
                    style={{
                      position: "absolute",
                      left: "-9999px",
                      top: "-9999px",
                    }}
                  >
                    <label htmlFor="insightly_additional_field">Comments</label>
                    <input
                      type="text"
                      id="insightly_additional_field"
                      name="insightly_additional_field"
                      autoComplete="off"
                    />
                  </div>
                </Grid>
              </div>
              
              {/* recaptcha block */}
              <div className='my-3 max-w-2xl'>
                <div className="g-recaptcha" data-sitekey={ sitekey } data-size={!bSmall ? 'normal' : 'compact' }></div>
              </div>
              
              <br/>
              <div className='flex-col content-center w-full'>
                <div className='flex justify-center'>
                  <button
                    className="btn m-2"
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    className="btn m-2" 
                    type="reset"
                  >
                    Reset
                  </button>

                </div>
                
                { !submitted ?
                  <p className='text-center mt-2'>*required field</p>
                  :
                  <p className='text-center text-green-700 mt-2 text-lg bg-green-200 rounded-lg p-1'>Thank you. Your message as been submitted. We will get back to you very soon.</p>
                }
                
              </div>
            </Grid>
          </form>
        </div>
      </Paper>

      {/* <br/>
      <div style='position:absolute; left:-9999px; top: -9999px;'>
        <label for='insightly_additional_field'>Comments</label>
        <input type='text' id='insightly_additional_field' name='insightly_additional_field'></div>
        <button class='g-recaptcha' data-sitekey='6LciOVEfAAAAAOlGAcZpavQTSBrW0j0mpeiqno_A' data-callback='onSubmit' data-action='submit'>Submit</button></form> */}
    </div>
  );
}

export default Contact;
