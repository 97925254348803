import { Box, Typography } from "@material-ui/core";
import React from "react";
//import { ExternalLink } from "react-external-link";
import { ExternalLink } from "react-external-link/dist/index.cjs";
import { Helmet } from "react-helmet";

import companies from "../Components/data/allcompanies";

const OurSubsidiary = () => {
  return (
    <div className="OurWork">
      <Helmet>
        <title>The Glimpse Group subsidiary companies | Diverse sectors and industries</title>
        <meta
          name="description"
          content="Learn which subsidiary company best suits your needs. Our diverse roster of VR and AR companies cover a wide range of sectors."
        />
      </Helmet>
      <div className="m__bottom m__top">
        <h1 className="text-gray-600 font-light" style={{ fontFamily: 'Montserrat' }}>Our Companies</h1>
      </div>
      <div className="card-grid-responsive">
        {companies
          .filter(comp => comp.id !== 7 && comp.id !== 15)
          .map(comp => {
            return (
              <div className="card" key={comp.id}>
                <Box height={160} className='flex justify-center'>
                  { comp.id !== 12 ? 
                    <img
                    src={comp.image}
                    alt={comp.alt}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      filter: comp.invert ? "invert(1)" : "invert(0)",
                    }}
                  /> 
                  : 
                  <img
                    src={comp.image2}
                    alt={comp.alt}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      filter: comp.invert ? "invert(1)" : "invert(0)",
                    }}
                  />
                   }
                  
                </Box>
                <Box p={2}>
                  
                  <Typography variant="subtitle2" style={{ height: "4rem" }}>
                    {comp.description}
                  </Typography>
                </Box>

                {/* <div className="card-content">
                  <h4>{comp.alt}</h4>
                  <div className="card-content-date">
                    <i className="far fa-calendar-alt"></i>
                    <span> {comp.date}</span>
                  </div>
                </div> */}
                <div >

                  <ExternalLink href={comp.link} className="cta">
                    <span className='text-gray-600'>
                      Visit Site <i className="fas fa-arrow-right"></i>
                    </span>
                  </ExternalLink>
                </div>
              </div>
            );
          })}
      </div>
      
    </div>
  );
};

export default OurSubsidiary;
