import React from "react";
import { ExternalLink } from "react-external-link/dist/index.cjs";

function Companies({ companieItem }) {
  return (
    <React.Fragment>
      <div className="Companies">
        <div className="honeycomb">
          {companieItem.map(item => {
            return (
              <div className="hexagon" key={item.id}>
                <div className={item.class}>
                  { item.id === 15 ? 
                    <div className="hex__front__soon clip ">
                      <img className="hex-img relative" src={item.image} alt={item.alt} />
                      <p className='text-center absolute bottom-12 left-12 text-white'>coming soon</p>
                    </div>
                  : 
                    <div className="hex__front clip">
                      <img className="hex-img" src={item.image} alt={item.alt} />
                    </div>
                  }
                  
                  <div className="hex__back clip">
                    <div className="hex__body">
                      <p>{item.description}</p>
                      <ExternalLink href={item.link} className="cta">
                        <span>
                          Visit Site <i className="fas fa-arrow-right"></i>
                        </span>
                      </ExternalLink>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="honey-m">
          {companieItem.map(item => {
            return (
              <div className="hexagon" key={item.id}>
                <div className="hexagon__content_noflip">
                  <ExternalLink href={item.link}>
                    { item.id === 15 ? 
                      <div className="hex__front__soon clip ">
                        <img className="hex-img relative" src={item.image} alt={item.alt} />
                        <p className='text-center absolute bottom-5 left-3 smallMobile:bottom-7 smallMobile:left-6 md:left-4 text-white'>coming soon</p>
                      </div>
                    : 
                      <div className="hex__front clip">
                        <img className="hex-img" src={item.image} alt={item.alt} />
                      </div>
                    }
                  </ExternalLink>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Companies;
