import React from 'react'
import { Helmet } from 'react-helmet';
import ourwork from '../Components/data/allourwork'
import { Link } from "react-router-dom"



const OurWork = () => {

  return (
    <div className='OurWork'>
      <Helmet>
        <title>The Glimpse Group client case studies | VR and AR solutions</title>
        <meta
          name="description"
          content="See what we’ve accomplished and what we can do for you. Explore our past projects, read client endorsements, and watch highlight reels. "
        />
      </Helmet>
      <div className='m__bottom m__top'>
        <h1 className="text-gray-600 font-light" style={{ fontFamily: 'Montserrat' }}>Our Work</h1>
      </div>
      <div className='card-grid-responsive'>
        {
          ourwork.map((item)=>{
            return <div className='card' key={item.id}>
                <div className='top-img'>
                  <img src={ item.image }  alt={item.title}/>
                </div>
                <div className='card-content'>
                  <h4>{item.title}</h4>
                  <div className='card-content-date'>
                    <i className="far fa-calendar-alt"></i>
                    <span> { item.date }</span>
                  </div>
                </div>
                <Link to={`/our-work/${item.title}`} >
                  <button className='btn'>View</button>
                </Link>
              </div>
          })
        }
      </div>
    </div>
  )
}

export default OurWork;