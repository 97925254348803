import React from "react";
import { useState } from 'react'
import { Helmet } from "react-helmet";
import pressc from '../../Components/data/allpress'
import Dates from '../../Components/Dates'
import PressCoverageItems from "../../Components/PressCoverageItems";

const allDates = ['All', ...new Set(pressc.map(item => item.date))];

function PressCoverage () {
  const [dates] = useState(allDates);
  const [pressItems, setPressItems] = useState(pressc);

  const filter = (date) =>{
    if(date === 'All'){
        setPressItems(pressc)
        return;
    }
    const filteredData  = pressc.filter((item)=>{
        return item.date === date;
    })
    setPressItems(filteredData);
}
  return (
    <div className="Press m__top">
      <Helmet>
        <title>The Glimpse Group news | Coverage by leading outlets</title>
        <meta
          name="description"
          content="Read the latest press coverage from leading outlets. Insightful reporting from tech experts and thought leaders around the world. "
        />
      </Helmet>
      <div className="main-content">
        <span className="text-gray-600 font-light" style={{ fontFamily: 'Montserrat' }}>
          <h4>YOUR TRUSTED SOURCE FOR VR AND AR INFORMATION</h4>
        </span>
        <h1 className="text-gray-600 font-light" style={{ fontFamily: 'Montserrat' }}>Press Coverage</h1>
          {/* <p>Glimpse is your source for information regrading the enterprise <br/> development of VR and AR software services.</p> */}
      </div>
      <Dates filter={filter} dates={dates}/>
      <br/>
      <div className="press-content">
        <PressCoverageItems pressItem={pressItems}/>
      </div>
    </div>
  )
}

export default PressCoverage;